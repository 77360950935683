import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Award, CoCreationContest, ContestAwards, RevisionUpload } from '../../global';
import {
  Datestr,
  finalString,
  fullPathLogo,
  fullPathLogoDesign,
  revisionDateString,
  setTimelines,
} from '../../helpers/utils';
import {
  faAngleDown,
  faAngleUp,
  faChevronDown,
  faChevronUp,
  faCloudArrowUp,
  faExclamation,
  faFileArrowDown,
  faPlus,
  faRemove,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useStoreState } from 'pullstate';
import DslStore, { setContestTags, setCurrentContest } from '../../store/DslStore';
import { getCurrentContest, getCurrentUser } from '../../store/Selectors';
import NDAForm from '../../components/Contest/NDAForm/NDAForm';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styles from './Brief.module.scss';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../helpers/axiosHelper';
import { AwardRow } from '../../components/Contest/AwardRow/AwardRow';

interface CoCreationProps {
  contest: CoCreationContest;
}

const CoCreationBrief: React.FC<CoCreationProps> = (props) => {
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const currentContest = useStoreState(DslStore, getCurrentContest);
  const [revisionOpen, setRevisionOpen] = useState(false);
  const [revisionLength, setRevisionLength] = useState(0);
  const [revisionError, setRevisionError] = useState(false);
  const [animation, setAnimation] = useState(false);
  const date = Math.trunc(Datestr(new Date().toISOString()) / 86400000);
  const startDateNumber = Math.trunc(Datestr(props.contest.startUploadDate) / 86400000);
  const endDateNumber = Math.trunc(Datestr(props.contest.endUploadDate) / 86400000);
  const [phaseOpen, setPhaseOpen] = useState(startDateNumber < date && date < endDateNumber ? true : false);
  const [ndaauth, setNdaAuth] = useState(true);
  const [accesscodeauth, setAccessCodeAuth] = useState(true);

  const [responseRevision, setResponseRevision] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RevisionUpload>();
  const redirect = useNavigate();

  useEffect(() => {
    console.log(props.contest);
    console.log(props.contest.startUploadDate);
    console.log(date);
    console.log(startDateNumber);
    console.log(date > startDateNumber);

    if (date > startDateNumber && date < endDateNumber) {
      setTimelines(
        props.contest.startUploadDate,
        props.contest.endUploadDate,
        props.contest.endVotingDate,
        props.contest.hypotheticalEndDate,
        props.contest
      );
    }

    // setTimelines();
  }, [props.contest]);

  const openBriefFile = (path: string) => {
    const element = document.createElement('a');
    element.href = `${fullPathLogo(path)}`;
    element.target = '_blank';
    element.click();
  };

  const downloadKit = () => {
    const element = document.createElement('a');
    element.href = `${fullPathLogo(props.contest?.briefKitAttachment.s3Path)}`;
    element.target = '_blank';
    element.click();
  };

  const contestrevision = () => {
    console.log(props.contest?.revision1, props.contest?.revision2);
    if (props.contest.revision2 !== null) {
      return false;
    } else if (props.contest.revision1 !== null) {
      return true;
    } else {
      return true;
    }
  };

  const handleRevisionLength = (e: any) => {
    let revision = e.target.value;
    setRevisionLength(revision.length);
  };

  const checkRevision = (revision: string) => {
    console.log(revision.length);
    if (revisionLength > 1000) {
      setRevisionError(true);
    } else {
      publishRevision(revision);
    }
  };

  const setTags = async (contestId: number) => {
    let contestTags = await axiosHelper.getContestTags(contestId);
    if (contestTags) {
      console.log(contestTags);
      setContestTags(contestTags.tags);
    }
  };

  const fetchContestByName = (contestName?: string) => {
    console.log('BY NAME');
    if (contestName) {
      axiosHelper
        .retrieveContestByName(contestName)
        .then((response) => {
          // console.log(response);
          setCurrentContest(response.data);
          setTags(response.data.tags);
        })
        .catch((error) => {
          throw error;
        });
    }
  };

  const publishRevision = async (revision: string) => {
    if (currentContest?.nameForUrl) {
      console.log('publish');
      if (loggedUser?.id === currentContest?.userId) {
        console.log('ciao');
        if (revision.length > 0) {
          console.log(currentContest?.revision1, currentContest?.revision2);
          if (currentContest?.revision1 === null) {
            console.log('revision1');
            await axiosHelper
              .revision1Contest(revision, currentContest.id)
              ?.then((response) => {
                fetchContestByName(currentContest?.nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          } else if (currentContest.revision2 === null) {
            console.log('revision2');
            await axiosHelper
              .revision2Contest(revision, currentContest.id)
              .then((response) => {
                fetchContestByName(currentContest?.nameForUrl);
                console.log(response);
              })
              .catch((error) => {
                setResponseRevision(error.response.status);
              });
          }
        } else {
          console.log('no revision');
        }
      } else {
        console.log('no user');
      }
    }
  };

  const onSubmit = (data: any) => {
    console.log(data.revision);
    checkRevision(data.revision);
  };

  return (
    <>
      <div className="row  px-1">
        <div className="col-12  px-0">
          <>
            <div
              className="col-12 bg-blue  mb-5  align-items-center justify-content-center d-flex"
              style={{ height: '30px', borderRadius: '8px', cursor: 'pointer' }}
              // onClick={() => setPhaseOpen(!phaseOpen)}
              onClick={() => date > startDateNumber && setPhaseOpen(!phaseOpen)}
            >
              <div className="col-2   "></div>
              <div className="col-8 d-flex justify-content-center  ">
                <span className="text-xxl color-light fw-bold" style={{ fontSize: '2rem' }}>
                  {props.contest.contestType.name} phase
                </span>
              </div>
              <div
                className={`col-2 d-flex justify-content-end align-items-center ${styles.toggle} ${
                  phaseOpen && styles.open
                }`}
                // onClick={() => setPhaseOpen(!phaseOpen)}
                onClick={() => date > startDateNumber && setPhaseOpen(!phaseOpen)}
              >
                <div className={`${styles.arrow} `}> </div>
              </div>
            </div>
            {phaseOpen && (
              <>
                <div className="row mb-5">
                  <div className="col-12  justify-content-center col-md-3 text-16 align-middle fw-bold mb-3">
                    Contest materials
                  </div>
                  {/* {props.contest?.briefAttachment && ( */}
                  <div className="col-12 col-md-3">
                    <button
                      className="btn-dark-blue-materials  full mb-4 rounded "
                      onClick={() => openBriefFile(props.contest.briefAttachment.s3Path)}
                      disabled={props.contest.briefAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>BRIEF ENG (.pdf)</div>
                    </button>
                  </div>
                  {/* )} */}
                  {/* {props.contest?.briefKitAttachment && ( */}
                  <div className="col-12 col-md-3">
                    <button
                      className="btn-dark-blue-materials  full mb-4 rounded "
                      onClick={() => openBriefFile(props.contest.briefKitAttachment.s3Path)}
                      disabled={props.contest.briefKitAttachment ? false : true}
                    >
                      <div className="btn-icon">
                        <FontAwesomeIcon
                          icon={faFileArrowDown as IconProp}
                          style={{ fontSize: '1.75rem' }}
                          className="text color-light"
                        />
                      </div>
                      <div>MATERIAL FILES (.pdf)</div>
                    </button>
                  </div>
                  {/* )} */}
                </div>

                <div className="row mb-5">
                  <div className="col-12 col-md-3 text-16 fw-bold mb-3">Summary</div>
                  <div className="col-12 col-md-9">
                    {/* <p>{contest?.textDescription}</p>  */}
                    <ReactMarkdown
                      className="text-16"
                      children={finalString(props.contest.textDescription)}
                      remarkPlugins={[remarkGfm]}
                    />
                  </div>
                </div>
                {/* <div className="row mb-5">
                  <div className="col-12 col-md-3 text fw-bold mb-3">Company description</div>
                  <div className="col-12 col-md-9">
                    <p>{props.contest.client.description}</p>
                  </div>
                </div> */}
                {loggedUser ? (
                  <div className="row mb-5">
                    <div className="col-12 col-md-3 text-16 fw-bold mb-3">Brief</div>
                    <div className="col-12 col-md-9">
                      <ReactMarkdown
                        className="text-16"
                        children={finalString(props.contest.brief)}
                        remarkPlugins={[remarkGfm]}
                      />
                    </div>
                  </div>
                ) : (
                  !props.contest.isPrivate && (
                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3">Brief</div>
                      <div className="col-12 col-md-9">
                        <ReactMarkdown
                          className="text-16"
                          children={finalString(props.contest.brief)}
                          remarkPlugins={[remarkGfm]}
                        />
                      </div>
                    </div>
                  )
                )}

                {props.contest.revision1 && loggedUser ? (
                  <div className="row mb-5">
                    <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                      Brief revision #1
                      <div
                        className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                        style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                      >
                        <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                      </div>
                    </div>
                    <div className="col-12 col-md-9">
                      <p className="text-16">{revisionDateString(props.contest.revision1Date)}</p>
                      <span>
                        <ReactMarkdown
                          className="text-16"
                          children={finalString(props.contest.revision1)}
                          remarkPlugins={[remarkGfm]}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  !props.contest.isPrivate &&
                  props.contest.revision1 && (
                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                        Brief revision #1
                        <div
                          className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                          style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                        >
                          <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <p className="text-16">{revisionDateString(props.contest.revision1Date)}</p>
                        <span>
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(props.contest.revision1)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </span>
                      </div>
                    </div>
                  )
                )}
                {props.contest.revision2 && loggedUser ? (
                  <>
                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                        Brief revision #2{' '}
                        <div
                          className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                          style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                        >
                          <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <p className="text-16">{revisionDateString(props.contest.revision2Date)}</p>
                        <span>
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(props.contest.revision2)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </span>
                      </div>
                    </div>
                  </>
                ) : (
                  !props.contest.isPrivate &&
                  props.contest.revision2 && (
                    <div className="row mb-5">
                      <div className="col-12 col-md-3 text-16 fw-bold mb-3 d-flex">
                        Brief revision #2{' '}
                        <div
                          className=" ms-4 bg-danger d-flex align-items-center justify-content-center"
                          style={{ borderRadius: '50%', height: '20px', width: '20px' }}
                        >
                          <FontAwesomeIcon icon={faExclamation as IconProp} className="text color-light" />
                        </div>
                      </div>
                      <div className="col-12 col-md-9">
                        <p className="text-16">{revisionDateString(props.contest.revision2Date)}</p>
                        <span>
                          <ReactMarkdown
                            className="text-16"
                            children={finalString(props.contest.revision2)}
                            remarkPlugins={[remarkGfm]}
                          />
                        </span>
                      </div>
                    </div>
                  )
                )}

                {props.contest.awards &&
                  props.contest.awards.map(
                    (item: Award, index: number) => (
                      console.log(item),
                      (
                        <div className="mb-5 px-1" key={index}>
                          <AwardRow
                            contestAward={item}
                            designImage={fullPathLogoDesign(
                              item.design
                                ? item.design.imageAttachment
                                  ? item.design.imageAttachment.s3Path
                                  : ''
                                : ''
                            )}
                            image={
                              fullPathLogo(
                                item.design
                                  ? item.design.designer.logoAttachment
                                    ? item.design.designer.logoAttachment.s3Path
                                    : ''
                                  : ''
                              )
                              // item.design
                              //   ? item.design.designer
                              //     ? item.design.designer.logoAttachment
                              //       ? item.design.designer.logoAttachment.s3Path
                              //       : ''
                              //     : ''
                              //   : ''
                            }
                          ></AwardRow>
                        </div>
                      )
                    )
                  )}

                {props.contest.hasNDA && !props.contest.isClosed &&<></> }
                {loggedUser ? (
                  <>
                    {loggedUser?.userType === 'C' && loggedUser.id === props.contest.client.id && contestrevision() && (
                      <>
                        <div className="row mb-5">
                          <div className="col-12 col-md-8"></div>
                          <div className="col-12 col-md-4 ">
                            <button
                              className=" btn-dark_blue rounded full  align-items-center d-flex"
                              onClick={() => setRevisionOpen(true)}
                            >
                              <span className="me-5">add brief revision</span>
                              <FontAwesomeIcon
                                icon={faPlus as IconProp}
                                style={{ fontSize: '2rem' }}
                                className="text color-light"
                              />
                            </button>
                          </div>
                        </div>
                        {revisionOpen && (
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row mb-5">
                              <div className="col-12 col-md-3 text-16 fw-bold mb-3">Brief revision</div>
                              <div className="col-12 col-md-9">
                                <div className="col-12 d-flex justify-content-between mb-4  ">
                                  <div className="d-flex align-items-center">
                                    <span className="text-16">You can add up to 2 revisions to your brief</span>
                                  </div>
                                  <div className="d-flex justify-content-center align-items-center ">
                                    <span className="fw-bold"> Remove</span>
                                    <div
                                      onClick={() => setRevisionOpen(false)}
                                      className={`ms-3 rounded d-flex align-items-center justify-content-center ${styles.removeRevision}`}
                                    >
                                      <FontAwesomeIcon icon={faRemove as IconProp} className="text color-light" />
                                    </div>
                                  </div>
                                </div>
                                <textarea
                                  placeholder="Please write here the revision"
                                  {...register('revision', {
                                    onChange: handleRevisionLength,
                                    required: false,
                                    maxLength: 1000,
                                  })}
                                ></textarea>
                                <div className="row">
                                  <div className="col-10">
                                    <>{errors.revision ||
                                      (revisionError && (
                                        <span className="text-xl color-danger">
                                          <b>You have exceeded the limit of 1000 characters</b>
                                        </span>
                                      ))}</>
                                  </div>
                                  <div className="col-2  d-flex justify-content-end">
                                    <span>{revisionLength}/1000</span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-3 "></div>
                              <div className="col-2 ">
                                <div className="">
                                  <button className="btn-dark-blue full submit  rounded">publish</button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </>
        </div>
      </div>
    </>
  );
};
export default CoCreationBrief;

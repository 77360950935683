import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faPencil, faTrophy, faIndustry } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestRow.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { Contest, User } from '../../../global';
import axiosHelper from '../../../helpers/axiosHelper';
import DslStore, { setCoCreationContest, setCommunityUser, setContestTags, setCurrentContest } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import designDefaultImg from '../../../assets/Design.png';
import { computeContestStatus, dateColorForStatus, getContestStatusText, progressNumber } from '../../../helpers/utilities';
import { useAuth } from '../../../context/AuthContext';
import { preload } from 'swr';
import { ROUTEPATHS } from '../../../routes/routePaths';

interface IContestRowWLF extends ICardInfo {
  contest: Contest;
  clickHandler?: (link: string, popupText: string) => void;
}

const ContestRowGallery = (props: { items: string[] }) => {
  let maxItems = props.items.slice(0, 5);
  let missingItems = 5 - maxItems.length;

  for (let i = 0; i < missingItems; i++) {
    maxItems.push('');
  }

  return (
    <>
      {maxItems.map((item, index) => (
        <div key={index} className={`${styles.contestRowGalleryItem}  ${index % 2 === 0 ? 'bg-light-gray-2' : 'bg-light-gray'}`}>
          {item ? <img src={item} alt="Gallery Item Cover" /> : <Spinner size={5} />}
        </div>
      ))}
    </>
  );
};

const ContestRowWLF: React.FC<IContestRowWLF> = ({ contest, clickHandler }) => {
  const { loggedUser: user, isContestLiked, toggleContestLiked } = useAuth();
  const redirect = useNavigate();
  const [dateColor, setDateColor] = useState(() => dateColorForStatus(contest));
  const [gallery, setGallery] = useState<string[]>([]);

  const galleryImages = async () => {
    await axiosHelper.getContest5DesignImages(contest.id)?.then((response) => {
      let ciccio: string[] = [];
      response.map((item: any) => (item.image1Attachment ? ciccio.push(item.image1Attachment.s3Path) : ciccio.push(designDefaultImg)));
      setGallery(ciccio);
    });
  };

  useEffect(() => {
    setGallery([]);
    !contest.isPrivate && contest.contestType !== 'Link to other' && galleryImages();
  }, [contest]);

  const goToDetail = async (userid: number) => {
    console.log(userid);

    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label') {
      redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
    } else {
      // alert('link to other');
    }
  };


  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    if (contestId) {
      if (contest.contestType === 'Link to other') {
        if (clickHandler) {
          clickHandler(contest.linkToUrl, contest.linkDescription);
        }
      } else if (contest.contestType === 'White label') {
        if (clickHandler) {
          clickHandler('https://innovation.artsana.com/Contest/' + contest.nameForUrl + '/Brief', 'You will be redirected to the Artsana platform _innovation.artsana.com_');
        }
      } else {
        preload('contestname|' + contest.nameForUrl, axiosHelper.contestsFetcher);
        if (contest.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }
        redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      }
    }
  };

  const goToContestGallery = async () => {
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label' && !contest.isPrivate) {
        redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
    }
  };

  const goToAwards = async () => {
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label') {
      redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_AWARDS}`);
    }
  };

  return (
    <div className={`${styles.contestRowWrapper} col-12 mb-5 position-relative `}>
      <MultiActionButtonsWrapper position="top-right" rounded>
        {contest.isPrivate && <ActionButton label="Private gallery" icon="private" />}
        {contest.accessCode && <ActionButton label="By invitation code contest" icon="accesscode" />}
        {!contest.accessCode && <ActionButton label="Global contest" icon="global" />}
        {contest.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
      </MultiActionButtonsWrapper>
      <div className="row bg-light">
        <div className="col-12 col-lg-7 d-flex flex-nowrap px-0">
          {/* Cover Image */}
          <div className={`${contest.image ? styles.contestRowImage : styles.contestRowImagePlaceholder} bg-light-gray-2 flex-shrink-0 position-relative`}>
            {contest.image ? (
              <img src={contest.image} alt="Contest Cover" />
            ) : // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
            null}
            <div className={styles.statusBar}>
              <StatusBar status={computeContestStatus(contest)} progress={progressNumber(contest)} />
            </div>
            {!isContestLiked(contest) && <ActionButton icon="addFavorite" color={!user ? 'light-gray-3' : ''} position="top-right" action={() => toggleContestLiked(contest)} />}
            {isContestLiked(contest) && <ActionButton icon="confirm" position="top-right" color="success" action={() => toggleContestLiked(contest)} />}
          </div>
          {/* Project Info */}
          <div className="flex-grow-1">
            <div className="row ps-0 flex-grow-1 h-100">
              {/* Project Title */}
              <div className="col-12  col-lg-5  d-flex flex-wrap  ps-5 mt-lg-0" style={{ height: '90px' }}>
                <div className="d-flex align-items-center pt-2 mb-1  w-100">
                  <h3 onClick={() => getContestDetails(contest.id)} className="text-xl d-flex fw-bold color-blue cursor-pointer">
                    {contest.name}
                  </h3>
                </div>
                <div className="d-flex align-items-end  mb-1 ">
                  <span className="text-xl d-flex">
                    {contest.contestType === 'Co-creation' ? 'Co-creation' : contest.category === 'Link to Other' ? 'Partnership' : contest.category}
                  </span>
                </div>
              </div>
              {/* Project Author */}
              <div className="col-6 col-lg-4 d-flex flex-column justify-content-center ps-5">
                <div className="d-flex justify-content-start">
                  <div className="d-flex flex-column justify-content-center">
                    <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text text-start w-100   color-blue" style={{ overflow: 'hidden' }} onClick={() => goToDetail(contest.userId)}>
                    <span className="cursor-pointer ">{contest.userName ?? contest.linkToClient}</span>
                  </div>
                </div>
              </div>
              {/* Project Stats */}
              <div className="col-6 col-lg-3 d-flex flex-column justify-content-center px-2">
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className={`text-s text-start color-${dateColor}`}>{getContestStatusText(contest)}</div>
                </div>
                <div className="d-flex justify-content-start mb-3">
                  <div className="d-flex flex-row align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faPencil as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div
                    className={`text-s text-start ${contest.isPrivate || contest.contestType == 'Link to other' ? '' : 'color-blue'} `}
                    onClick={() => !contest.isPrivate && contest.contestType !== 'Link to other' && goToContestGallery()}
                  >
                    {contest.entries | 0} entries
                  </div>
                </div>
                <div className="d-flex justify-content-start" onClick={()=>goToAwards()}>
                  <div className="d-flex flex-row align-items-center">
                    <FontAwesomeIcon icon={faTrophy as IconProp} className=" text color-blue me-3" />
                  </div>
                  <div className="text-s text-start color-blue">{contest.linkToAward ? `${contest.linkToAward}` : contest.prizes ? `€ ${contest.prizes} award` : 'Awards'}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Project Gallery */}
        <div className={`${styles.contestRowGallery} col-12 col-lg-5 d-flex justify-content-center justify-content-lg-end px-0 mt-3 mt-lg-0`}>
          {!contest.isPrivate && contest.contestType !== 'Link to other' && !contest.hideGalleryTab && gallery.length > 0 && <ContestRowGallery items={gallery} />}
        </div>
      </div>
    </div>
  );
};

export default ContestRowWLF;

import React, { useCallback, useEffect, useState } from 'react';
import AppFooter from '../../components/AppFooter/AppFooter';
import AppHeader from '../../components/AppHeader/AppHeader';
import ContestRowBigWLF from '../../components/Contest/ContestRowBig/ContestRowBigWLF';

import PopUpRedirectContest from '../../components/PopUp/PopUpRedirectContest/PopUpRedirectContest';
import DslStore, { loadContestsByName, setCurrentPage, setNumberOfPages } from '../../store/DslStore';
import { useStoreState } from 'pullstate';
import { getFooterMenu, getHeaderMenu, getMobileMode } from '../../store/Selectors';

import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import footerImg from '../../assets/footer_placeholder_transparent.png';
import { Contest } from '../../global';
import ContestsNavigationWLF from '../../components/Contest/ContestsNavigation/ContestsNavigationWLF';
import useSWR from 'swr';
import axiosHelper from '../../helpers/axiosHelper';
import { computeContestStatus } from '../../helpers/utilities';
import PaginationSwitcherWLF from '../../components/Contest/ContestsNavigation/PaginationSwitcherWLF';
import ContestCardWLF from '../../components/Contest/ContestCard/ContestCardWLF';
import ContestRowWLF from '../../components/Contest/ContestRow/ContestRowWLF';
import { getContestSearchParams } from '../../store/Selectors';
import { NoContent } from '../../components/Reusable/NoContent';
import AppHeaderWLF from '../../components/AppHeader/AppHeaderWLF';


const ContestListWLF: React.FC = () => {
  const { data, error, isLoading } = useSWR('all', axiosHelper.contestsFetcher);
  const contestSearchParams = useStoreState(DslStore, getContestSearchParams);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const [layoutContest, setLayoutContest] = useState(1);
  const [popup, setPopup] = useState(false);
  const [popupLink, setPopupLink] = useState('');
  const [linkToDescription, setLinkToDescription] = useState('');
  const mobileMode = useStoreState(DslStore, getMobileMode);
  const [shouldShowBigRows, setShouldShowBigRows] = useState(false); // true solo se pagina 1 e sort by date
  const elementsPerPage = 20;
  const [ongoingContests, setOngoingContests] = useState<Contest[]>([]);
  const [otherContests, setOtherContests] = useState<Contest[]>([]);
  const [allContests, setAllContests] = useState<Contest[]>([]);
  const [filteredContests, setFilteredContests] = useState<Contest[]>([]);
  const [currentContests, setCurrentContests] = useState<Contest[]>([]);
  const [visibleContests, setVisibleContests] = useState<Contest[]>([]);
  const [direction, setDirection] = useState(true);
  const [showNoResults, setShowNoResults] = useState(false);

  useEffect(() => {
    if (data) {
      setAllContests(data);
      loadContestsByName(data);
    }
  }, [data]);
  
  const sortContexts = useCallback(() => {
    console.log('sortContexts ' + direction);
    if (filteredContests.length === 0) return [];
    const contests = filteredContests;
    const result = contests.sort((a, b) => {
      if (contestSearchParams.sortBy === 'name') {
        return direction ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
      } else if (contestSearchParams.sortBy === 'date') {
        const dateA = new Date(a.endUploadDate).getTime();
        const dateB = new Date(b.endUploadDate).getTime();
        const searchEndClientVotingDateA = new Date(a.searchEndClientVotingDate).getTime();
        const searchEndClientVotingDateB = new Date(b.searchEndClientVotingDate).getTime();
        if (searchEndClientVotingDateA === searchEndClientVotingDateB) {
          return direction ? dateB - dateA : dateA - dateB;
        }
        return direction ? searchEndClientVotingDateB - searchEndClientVotingDateA : searchEndClientVotingDateA - searchEndClientVotingDateB;
      } else if (contestSearchParams.sortBy === 'entries') {
        return direction ? a.entries - b.entries : b.entries - a.entries;
      } else if (contestSearchParams.sortBy === 'award') {
        return direction ? a.prizes - b.prizes : b.prizes - a.prizes;
      }
      return 0;
    });
    console.log(result[0].name);
    return result;
  }, [filteredContests, contestSearchParams.sortBy, direction]);

  useEffect(() => {
    console.log('contestSearchParams.currentPage ' + contestSearchParams.currentPage);
    const indexOfLastContest = contestSearchParams.currentPage * elementsPerPage;
    const indexOfFirstContest = indexOfLastContest - elementsPerPage;
    setCurrentContests(otherContests.slice(indexOfFirstContest, indexOfLastContest));
    setVisibleContests(sortContexts().slice(indexOfFirstContest, indexOfLastContest));
    console.log('############### INDEX OF LAST CONTEST ' + indexOfLastContest);
    console.log('############### INDEX OF FIRST CONTEST ' + indexOfFirstContest);
  }, [contestSearchParams.currentPage, otherContests, sortContexts]);

  useEffect(() => {
    if (allContests) {
      console.log('allContests USE EFFECT');
      const category = contestSearchParams.category;
      const filter = contestSearchParams.filterBy;
      var categorizedContests: Contest[] = [...allContests];

      if (category) {
        categorizedContests = allContests.filter((contest: Contest) => contest.category === category);
      }
      var ongoingContests: Contest[] = [];
      var otherContests: Contest[] = [];
      var filteredContests: Contest[] = [];

      categorizedContests.forEach((contest: Contest) => {
        if (filter === 'all') {
          if (computeContestStatus(contest) === 'ongoing') {
            ongoingContests.push(contest);
            filteredContests.push(contest);
          } else {
            otherContests.push(contest);
            filteredContests.push(contest);
          }
        } else if (filter === 'active') {
          if (computeContestStatus(contest) !== 'closed') {
            if (computeContestStatus(contest) === 'ongoing') {
              ongoingContests.push(contest);
              filteredContests.push(contest);
            } else {
              otherContests.push(contest);
              filteredContests.push(contest);
            }
          }
        } else if (filter === 'closed') {
          if (computeContestStatus(contest) === 'closed') {
            otherContests.push(contest);
            filteredContests.push(contest);
          }
        }
      });
      setOngoingContests(ongoingContests);
      setOtherContests(otherContests);
      setFilteredContests(filteredContests);
      if (filteredContests.length === 0 && ongoingContests.length === 0 && otherContests.length === 0) {
        setShowNoResults(true);
      } else {
        setShowNoResults(false);
      }
    }
  }, [allContests, contestSearchParams.filterBy, contestSearchParams.category]);

  useEffect(() => {
    if (shouldShowBigRows) {
      var nOfPages = Math.ceil(otherContests.length / elementsPerPage);
      // if (nOfPages === 0) {
      //   nOfPages = 1;
      // }
      setNumberOfPages(nOfPages);
    } else {
      var noOfPages = Math.ceil(filteredContests.length / elementsPerPage);
      // if (noOfPages === 0) {
      //   nOfPages = 1;
      // }
      setNumberOfPages(noOfPages);
    }
  }, [filteredContests.length, otherContests.length, shouldShowBigRows]); // Add sorting to the dependency array

  useEffect(() => {
    if (contestSearchParams.filterBy === 'all') {
      var noOfPages = Math.ceil(otherContests.length / elementsPerPage);
      // if (noOfPages === 0) {
      //   noOfPages = 1;
      // }
      setNumberOfPages(noOfPages);
    }
  }, [contestSearchParams.filterBy, otherContests.length]); // Add sorting to the dependency array

  useEffect(() => {
    if (contestSearchParams.sortBy === 'date' && direction && !contestSearchParams.category) {
      setShouldShowBigRows(layoutContest === 1);
    } else {
      setShouldShowBigRows(false);
    }
  }, [layoutContest, contestSearchParams.sortBy, contestSearchParams.category, direction, shouldShowBigRows]);

  useEffect(() => {
    if (mobileMode) {
      setLayoutContest(1);
    }
  }, [mobileMode]);

  useEffect(() => {
    setDirection(contestSearchParams.orderBy === 'desc');
  }, [contestSearchParams.orderBy]);

  const handleExternalLink = (url: string, popupText: string) => {
    setPopupLink(url);
    setLinkToDescription(popupText);
    setPopup(true);
  };

  return (
    <div className="bg-light-gray">
      <AppHeaderWLF
        links={headerMenu}
        bgColor="#C3DE72"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        pageTitle="Contest"
        breadcrumbs={'Home/Contest'}
      />
      <main className="container mb-5 nogutter">
        <div className="row ">
          <div className="col-12  mb-3 ">
            <ContestsNavigationWLF
              currentLayout={layoutContest}
              onChangeLayout={setLayoutContest}
              showChangeLayout={!mobileMode}
            />
          </div>
        </div>
        <PopUpRedirectContest
          popup={popup}
          description={linkToDescription}
          close={() => setPopup(false)}
          link={popupLink}
        />
        {showNoResults ? (
          <NoContent />
        ) : (
          <>
            {layoutContest === 1 && (
              <>
                {shouldShowBigRows && contestSearchParams.currentPage === 1 && (
                  <div className="row mt-lg-3">
                    {ongoingContests.map((contest: Contest, index: number) => (
                      <ContestRowBigWLF key={index} contest={contest} clickHandler={handleExternalLink} />
                    ))}
                  </div>
                )}

                {shouldShowBigRows ? (
                  <div className="row ">
                    {currentContests.map((contest: Contest, index: number) => (
                      <ContestCardWLF key={index} contest={contest} clickHandler={handleExternalLink} />
                    ))}
                  </div>
                ) : (
                  <div className="row ">
                    {visibleContests.map((contest: Contest, index: number) => (
                      <ContestCardWLF key={index} contest={contest} clickHandler={handleExternalLink} />
                    ))}
                  </div>
                )}
              </>
            )}
            {layoutContest === 2 && (
              <div className="row mt-4 px-lg-4">
                {visibleContests.map((contest: Contest, index: number) => (
                  <ContestRowWLF key={index} contest={contest} clickHandler={handleExternalLink} />
                ))}
              </div>
            )}
          </>
        )}
        <div className="row ">
          <div className="col-12 px-4 d-flex justify-content-sm-start justify-content-md-end ">
            <PaginationSwitcherWLF />
          </div>
        </div>
      </main>
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default ContestListWLF;

import React, { useEffect, useRef, useState } from 'react';
import AppHeader from '../../components/AppHeader/AppHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import styles from './UploadContest.module.scss';
import ContestStats from '../../components/Community/ContestStats/ContestStats';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faFileVideo, faFileZipper } from '@fortawesome/free-regular-svg-icons';
import {
  faCircleQuestion,
  faMapMarkerAlt,
  faIndustry,
  faCloudArrowUp,
  faCircleExclamation,
  faXmark,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';

//Header Data
import headerimg from '../../assets/header_placeholder_transparent.png';
import headerLogo from '../../assets/desall_logo.png';
import { briefNavPages } from '../../mockdata/navPages';

//Footer Data
import footerImg from '../../assets/footer_placeholder_transparent.png';
import TimeLine from '../../components/TimeLine/TimeLine';
import ActionButton, { MultiActionButtonsWrapper } from '../../components/ActionButton/ActionButton';
import MiniTimeLine from '../../components/TimeLine/MiniTimeLine';
import SideActionsCard from '../../components/SideActionsCard/SideActionsCard';
import {
  getCoCreationContestStats,
  getCoCreationContests,
  getCommunityUser,
  getContestTags,
  getCurrentContest,
  getCurrentDesign,
  getCurrentUser,
  getFooterMenu,
  getHeaderMenu,
  getTags,
  getTimelinePassedUpload,
  getTimelinePassedCommunityVote,
  getTimelineTotUpload,
  getTimelineTotCommunityVote,
  getTimelineTotClientVote,
  getTimelinePassedClientVote,
  getAllCoCreationContests,
} from '../../store/Selectors';
import { useStoreState } from 'pullstate';
import DslStore, {
  setCommunityUser,
  setCurrentDesign,
  setPortfolioDesign,
  setUserAwards,
  setUserInspirations,
  setUserInspirationsCopy,
} from '../../store/DslStore';
import { finalSlicedString, finalString, numberVotes, registrationDateString } from '../../helpers/utils';
import axiosHelper from '../../helpers/axiosHelper';

import { PageNavigationBrief } from '../../components/PageNavigation/PageNavigation';
import { faFacebook, faPinterest, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { CoCreationContest, ContestTag, DSLTag, Design, DesignAttachment, DesignUpload, User } from '../../global';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PopUpLogin from '../../components/PopUp/PopUpLogin/PopUpLogin';
import UploadContestCoCreation from './UploadContestCoCreation';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { EditDraftNaming } from './EditDraftNaming';
import { TailSpin } from 'react-loader-spinner';
import PopUpPublishDraft from '../../components/PopUp/PopUpEditDraft/PopUpPublishDraft';
import { ROUTEPATHS } from '../routePaths';

const EditDraft: React.FC = () => {
  const contest = useStoreState(DslStore, getCurrentContest);
  const cocreationContests = useStoreState(DslStore, getCoCreationContests);
  const allcocoreations = useStoreState(DslStore, getAllCoCreationContests);
  const headerMenu = useStoreState(DslStore, getHeaderMenu);
  const loggedUser = useStoreState(DslStore, getCurrentUser);
  const footerMenu = useStoreState(DslStore, getFooterMenu);
  const design = useStoreState(DslStore, getCurrentDesign);
  const contestTag = useStoreState(DslStore, getContestTags);
  const userLogged = useStoreState(DslStore, getCurrentUser);
  const timelineTotUpload = useStoreState(DslStore, getTimelineTotUpload);
  const timelinePassedUpload = useStoreState(DslStore, getTimelinePassedUpload);
  const timelineTotCommunityVote = useStoreState(DslStore, getTimelineTotCommunityVote);
  const timelinePassedCommunityVote = useStoreState(DslStore, getTimelinePassedCommunityVote);
  const cocreationstats = useStoreState(DslStore, getCoCreationContestStats);
  const tags = useStoreState(DslStore, getTags);
  const timelineTotClientVote = useStoreState(DslStore, getTimelineTotClientVote);
  const timelinePassedClientVote = useStoreState(DslStore, getTimelinePassedClientVote);
  const accept = {'image/png':['.png'],'image/jpg':['.jpg'],'image/jpeg':['.jpeg']};

  const [suggestionArray, setSuggestionArray] = useState<DSLTag[]>([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<DesignUpload>();
  const redirect = useNavigate();
  const [tagsArray, setTagsArray] = useState<string[]>([]);
  const [tagString, setTagString] = useState('');
  const communityUser = useStoreState(DslStore, getCommunityUser);
  const [designNameLength, setDesignNameLength] = useState<number>(0);
  const [designAbstractLength, setDesignAbstractLength] = useState<number>(0);
  const [popup, setPopup] = useState(userLogged ? false : true);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedCoverImage, setUploadedCoverImage] = useState<File>();
  const [coverImage, setCoverImage] = useState(design?.image1Attachment);
  const [designImages, setDesignImages] = useState<DesignAttachment[]>([]);
  // const [coverError, setCoverError] = useState(false);
  const coverError = useRef(false);
  const designNameError = useRef(false);
  const abstractError = useRef(false);
  const tagsError = useRef(false);
  const agreementError = useRef(false);
  const [fileLimit, setFileLimit] = useState(true);
  const [archiveFile, setArchiveFile] = useState<File>();
  const [videoFile, setVideoFile] = useState<File>();
  const [agreement, setAgreement] = useState(design?.agreement);
  const uuidString = useRef<string>('');
  const filesTot = useRef(0);
  const formData = useRef<DesignUpload>();
  const [draft, setDraft] = useState(false);
  const [projName, setProjName] = useState(design?.name);
  const [projAbstract, setProjAbstract] = useState(design?.textAbstract);
  const [projDescr, setProjDescr] = useState(design?.textDescription);
  const [projArchive, setProjArchive] = useState(design?.archiveAttachment);
  const [projVideo, setProjVideo] = useState(design?.videoAttachment);
  const [popUpProjName, setPopUpProjName] = useState(false);
  const [popUpPrivacy, setPopUpPrivacy] = useState(false);
  const [popUpAbstract, setPopUpAbstract] = useState(false);
  const [popUpDescription, setPopUpDescription] = useState(false);
  const [popUpArchive, setPopUpArchive] = useState(false);
  const [popUpCover, setPopUpCover] = useState(false);
  const [popUpImages, setPopUpImages] = useState(false);
  const [popUpTags, setPopUpTags] = useState(false);
  const [popUpVideo, setPopUpVideo] = useState(false);
  const [popUpAgreement, setPopUpAgreement] = useState(false);
  const [progress, setProgress] = useState(0);
  const [designPrivacy, setDesignPrivacy] = useState(false);
  const [popUpPublishDraft, setPopUpPublishDraft] = useState(false);
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    console.log(draft);
  }, [draft]);

  let tlUC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let tlUCC = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotClientVote,
      passed: timelinePassedClientVote,
    },
  };

  let minitl1 = {
    upload: {
      total: timelineTotUpload,
      passed: timelinePassedUpload,
    },
    community: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
    client: {
      total: timelineTotCommunityVote,
      passed: timelinePassedCommunityVote,
    },
  };

  useEffect(() => {
    !userLogged && setPopup(true);
    checkUser();
    console.log(coverImage);
    const designAttachmentsArr: DesignAttachment[] = [];
    design?.image2Attachment && designAttachmentsArr.push(design.image2Attachment);
    design?.image3Attachment && designAttachmentsArr.push(design.image3Attachment);
    design?.image4Attachment && designAttachmentsArr.push(design.image4Attachment);
    design?.image5Attachment && designAttachmentsArr.push(design.image5Attachment);
    setDesignImages(designAttachmentsArr);
  }, []);

  const handleVideoSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 50) {
        setVideoFile(event.target.files?.[0]);
      } else {
        // alert('Upload file up to 50MB');
      }
    }
  };

  const handleArchiveSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event?.target?.files?.[0]);
    if (event?.target?.files) {
      let sizeMb = event.target.files?.[0].size / 1024 / 1024;
      if (sizeMb < 100) {
        setArchiveFile(event.target.files?.[0]);
      } else {
      }
    }
  };

  useEffect(() => {
    if (uploadedImages) {
      // console.log(uploadedImages);
    }
  }, [uploadedImages]);

  const onDrop = (e: any) => {
    // console.log(e);

    const chosenFiles = Array.prototype.slice.call(e);
    console.log(e?.[0]);
    handleUploadFiles(chosenFiles);
  };

  const onDropCover = (e: any) => {
    // console.log(e?.[0]);
    coverError.current = false;
    if (!coverImage) {
      setUploadedCoverImage(e?.[0]);
    } else {
      setCoverImage(undefined);
      setUploadedCoverImage(e?.[0]);
    }
  };

  const canPublishDraft = () => {
    if (design) {
      if (design.contest.contestType.name === 'Naming') {
        console.log('ciao');
        if (design.payoff && design.name && design.textDescription) {
          setPopUpPublishDraft(true);
        }
      } else {
        if (
          design.name &&
          design.textAbstract &&
          design.image1Attachment &&
          design.image1Attachment.s3Path &&
          design.tags.length > 0 &&
          design.agreement
        ) {
          setPopUpPublishDraft(true);
        }
      }
    }
  };

  const handleUploadFiles = (files: File[]) => {
    const uploaded: File[] = [...uploadedImages];
    let limitExceeded = false;
    files.some((file: File) => {
      if (uploaded.findIndex((f: File) => f.name === file.name) === -1) {
        console.log(uploaded);
        console.log(file.name);
        uploaded.push(file);
        if (uploaded.length + designImages.length > 4) setFileLimit(true);
        if (uploaded.length + designImages.length > 4) {
          // alert('You can only add a maximum of 5 files');
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) {
      console.log(uploaded);
      setUploadedImages(uploaded);
    }
    checkHowManyDesignImages();
  };

  const deleteImg = (index: number) => {
    const uploaded: File[] = [...uploadedImages];
    uploaded.splice(index, 1);
    setUploadedImages(uploaded);
  };

  const deleteDesignImg = (index: number) => {
    console.log(index);
    const draftImages: DesignAttachment[] = [...designImages];
    draftImages.splice(index, 1);

    removeImage(index + 2)?.then(() => {
      setDesignImages(draftImages);
      console.log('rimossa immagine: ' + index + 2);
    });
  };

  const removeCurrentCover = () => {
    removeImage(1)?.then(() => {
      setCoverImage(undefined);
      console.log('logo rimosso');
    });
  };

  const checkUser = () => {
    if (userLogged) {
      // console.log(userLogged?.username);
      // console.log(userLogged.firstName);
      // console.log(userLogged.lastName);
      // console.log(userLogged.mobileNumber);
      // console.log(userLogged.city);
      // console.log(userLogged.state);
      // console.log(userLogged.zipCode);
      // console.log(userLogged.country);
      // console.log(userLogged.job?.name);
      // console.log(userLogged.jobTitle);
      // console.log(userLogged.school1);
      // console.log(userLogged.freelanceLicense);
      if (
        userLogged?.username &&
        userLogged.firstName &&
        userLogged.lastName &&
        userLogged.mobileNumber &&
        userLogged.city &&
        userLogged.state &&
        userLogged.zipCode &&
        userLogged.country &&
        userLogged.job?.name &&
        userLogged.jobTitle &&
        userLogged.school1 &&
        userLogged.freelanceLicense
      ) {
        return true;
      } else {
        console.log('no completo');
        redirect(`/${ROUTEPATHS.COMMUNITY}/${userLogged?.nameForURL}/profile-editable`);
      }
    }
  };

  const tagstring = (e: string) => {
    setTagString(e);
  };

  const suggestedTagsString = () => {
    let tagsArr: string[] = [];
    // console.log(e);
    tagsArr = tagString.split(' ');

    setTagsArray(tagsArr);
    let suggestions: DSLTag[] = [];

    tags.map((item: DSLTag, index: number) => {
      if (item.name.includes(tagsArr[tagsArr.length - 1])) {
        // console.log(item.name);
        suggestions.push(item);
      }
    });
    setSuggestionArray(suggestions);
  };

  const addTag = (tag: string) => {
    // console.log(tag);
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    let stringWithSuggestion = string.replace(new RegExp(string.split(' ')[index] + '$'), tag + ' ');
    setTagString(stringWithSuggestion);
  };

  const tagStringCheck = () => {
    let string = tagString;
    let index = string.split(' ').length - 1;
    // console.log(string.split(' ')[index]);
    if (string.split(' ')[index] !== '') {
      return true;
    } else {
      return false;
    }
  };

  const initialTagString = () => {
    let designtagString: string = '';

    design?.tags.map((item: DSLTag) => {
      designtagString += item.name + ' ';
    });

    setTagString(designtagString);
  };

  useEffect(() => {
    suggestedTagsString();
    tagStringCheck();
  }, [tagString]);

  useEffect(() => {
    suggestedTagsString();
    if (tagString == '') {
      initialTagString();
    }
    console.log(contest?.isPrivate);
    // console.log(tags);
  }, []);

  const componiarray = () => {
    console.log('componiArray ');
    const arrayTot: File[] = [];
    if (uploadedCoverImage) {
      arrayTot.unshift(...arrayTot, uploadedCoverImage);
    }
    if (uploadedImages) {
      uploadedImages.map((item: File) => {
        arrayTot.push(item);
      });
    }
    if (videoFile) {
      console.log('componiarray add video');
      arrayTot.push(videoFile);
    }
    if (archiveFile) {
      console.log('componiarray add archive');
      arrayTot.push(archiveFile);
    }
    filesTot.current = arrayTot.length;
  };

  const hiddenOption = () => {
    if (design) {
      if (!design.contest.isPrivate && design.contest.startDate && design.contest.endUploadDate) {
        const dateNumber = new Date().getTime() / 86400000;
        const startDateNumber = new Date(design.contest.startDate).getTime() / 86400000;
        const endDateNumber = new Date(design.contest.endUploadDate).getTime() / 86400000;
        const halfDate = (endDateNumber - startDateNumber) / 2;
        if (dateNumber - startDateNumber < halfDate) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const onSubmit = async (data: DesignUpload) => {
    console.log(uploadedCoverImage);
    console.log(data);
    designNameError.current = false;
    abstractError.current = false;
    tagsError.current = false;
    agreementError.current = false;
    coverError.current = false;
    if (!data.designName) {
      designNameError.current = true;
    }
    if (!data.abstract && !draft) {
      abstractError.current = true;
    }
    if (!tagString && !draft) {
      tagsError.current = true;
    }

    if (agreement) {
      data.agreement = agreement;
    }
    if (!agreement) {
      agreementError.current = true;
    }
    let tags: string = data.tempTags;
    if (tags.charAt(tags.length - 1) === ' ') {
      data.tempTags = tags.substring(0, tags.length - 1);
      console.log(tags.substring(0, tags.length - 1));
    }
    if (upload) {
      if ((uploadedCoverImage || design?.image1Attachment.s3Path) && !draft) {
        setProgress(1);
        componiarray();
        if ((!uploadedCoverImage || !design?.image1Attachment) && !draft) coverError.current = true;
        formData.current = data;
        uuidString.current = '';
        formData.current.isHidden = designPrivacy;
        const arrayImages: File[] = [];
        if (uploadedCoverImage) {
          console.log('unshift image');
          arrayImages.unshift(...arrayImages, uploadedCoverImage);
        }
        if (uploadedImages) {
          console.log('unshift images');
          uploadedImages.map((item: File) => {
            arrayImages.push(item);
          });
        }

        // console.log(arrayImages);
        // console.log(videoFile);
        // console.log(archiveFile);

        if (arrayImages.length > 0 || videoFile || archiveFile) {
          if (arrayImages.length > 0) {
            console.log('images');
            await imagesPresignedUrl(arrayImages);
            setProgress(15);
            if (videoFile || archiveFile) {
              if (videoFile) {
                console.log('images + video');
                await videoPresignedUrl(videoFile);
                setProgress(40);
                if (archiveFile) {
                  console.log('images + video + archive');
                  await archivePresignedUrl(archiveFile);
                  setProgress(70);
                }
              } else if (archiveFile) {
                console.log('images + archive');
                await archivePresignedUrl(archiveFile);
                setProgress(70);
              }
            }
          } else if (videoFile) {
            console.log('video ');
            await videoPresignedUrl(videoFile);
            setProgress(50);
            if (archiveFile) {
              console.log(' video + archive');
              await archivePresignedUrl(archiveFile);
              setProgress(70);
            }
          } else if (archiveFile) {
            console.log('archive');
            await archivePresignedUrl(archiveFile);
            setProgress(70);
          }
        }

        if (design) {
          const uuids = uuidString.current.slice(0, -1);
          if (!draft) {
            console.log('publish design');
            if (agreement) {
              if (design.image1Attachment || uploadedCoverImage) {
                await axiosHelper.updateDraft(design.id, formData.current, false, uuids).then(async (response) => {
                  console.log(response);
                  await axiosHelper.getDesignerDetails(loggedUser?.id)?.then(async (designs) => {
                    await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                      setUserAwards(awards.awards);
                    });
                    await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                      setUserInspirations(inspirations.inspirations);
                      setUserInspirationsCopy(inspirations.inspirations);
                    });
                    setPortfolioDesign(designs.designs);
                    redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                  });
                });
              } else {
                coverError.current = true;
              }
            } else {
              agreementError.current = true;
            }
          } else if (draft) {
            console.log('update draft');

            if (data.agreement) {
              await axiosHelper.updateDraft(design.id, formData.current, true, uuids).then((response) => {
                console.log('prima chiamata');
                console.log(response);
                axiosHelper.getDesignerDetails(loggedUser?.id)?.then(async (designs) => {
                  await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                    setUserAwards(awards.awards);
                  });
                  await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                    setUserInspirations(inspirations.inspirations);
                    setUserInspirationsCopy(inspirations.inspirations);
                  });
                  setPortfolioDesign(designs.designs);
                  redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                });
              });
            } else {
              agreementError.current = true;
            }
          }
        }
      } else {
        if (uploadedCoverImage || uploadedImages) {
          componiarray();
          formData.current = data;
          uuidString.current = '';
          formData.current.isHidden = designPrivacy;
          const arrayImages: File[] = [];
          if (uploadedCoverImage) {
            console.log('unshift image');
            arrayImages.unshift(...arrayImages, uploadedCoverImage);
          }
          if (uploadedImages) {
            console.log('unshift images');
            uploadedImages.map((item: File) => {
              arrayImages.push(item);
            });
          }
        }
        if (draft && design?.contest) {
          if (data.designName) {
            if (agreement) {
              if (uploadedCoverImage || uploadedImages) {
                console.log('ho cover image');
                componiarray();
                const arrayImages: File[] = [];
                if (uploadedCoverImage) {
                  console.log('unshift image');
                  arrayImages.unshift(...arrayImages, uploadedCoverImage);
                }
                if (uploadedImages) {
                  console.log('unshift images');
                  uploadedImages.map((item: File) => {
                    arrayImages.push(item);
                  });
                }
                if (arrayImages.length > 0 || videoFile || archiveFile) {
                  if (arrayImages.length > 0) {
                    console.log('images');
                    await imagesPresignedUrl(arrayImages);
                    setProgress(15);
                    if (videoFile || archiveFile) {
                      if (videoFile) {
                        console.log('images + video');
                        await videoPresignedUrl(videoFile);
                        setProgress(40);
                        if (archiveFile) {
                          console.log('images + video + archive');
                          await archivePresignedUrl(archiveFile);
                          setProgress(70);
                        }
                      } else if (archiveFile) {
                        console.log('images + archive');
                        await archivePresignedUrl(archiveFile);
                        setProgress(70);
                      }
                    }
                  } else if (videoFile) {
                    console.log('video ');
                    await videoPresignedUrl(videoFile);
                    setProgress(50);
                    if (archiveFile) {
                      console.log(' video + archive');
                      await archivePresignedUrl(archiveFile);
                      setProgress(70);
                    }
                  } else if (archiveFile) {
                    console.log('archive');
                    await archivePresignedUrl(archiveFile);
                    setProgress(70);
                  }
                  const uuids = uuidString.current.slice(0, -1);

                  axiosHelper.updateDraft(design.id, data, true, uuids).then(async (response) => {
                    setProgress(85);
                    console.log(response);
                    setPortfolioDesign([]);
                    setTimeout(async () => {
                      await axiosHelper
                        .getDesignerDetails(loggedUser?.id)
                        ?.then(async (designs) => {
                          await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                            setUserAwards(awards.awards);
                          });
                          await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                            setUserInspirations(inspirations.inspirations);
                            setUserInspirationsCopy(inspirations.inspirations);
                          });
                          setPortfolioDesign(designs.designs);
                          setTimeout(() => {
                            redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                          }, 1000);
                        })
                        .catch((e) => {
                          setProgress(0);
                        });
                    }, 2000);
                  });
                }
              }
              console.log('draft senza immagini');
              if (videoFile || archiveFile) {
                if (videoFile) {
                  console.log('draft senza immagini ma con video');
                  await videoPresignedUrl(videoFile);
                  setProgress(50);
                }
                if (archiveFile) {
                  console.log('draft senza immagini ma con archive');
                  await archivePresignedUrl(archiveFile);
                  setProgress(70);
                }
                const uuids = uuidString.current.slice(0, -1);

                axiosHelper.updateDraft(design.id, data, true, uuids).then(async (response) => {
                  setProgress(85);
                  console.log(response);
                  setPortfolioDesign([]);
                  setTimeout(async () => {
                    await axiosHelper
                      .getDesignerDetails(loggedUser?.id)
                      ?.then(async (designs) => {
                        await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                          setUserAwards(awards.awards);
                        });
                        await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                          setUserInspirations(inspirations.inspirations);
                          setUserInspirationsCopy(inspirations.inspirations);
                        });
                        setPortfolioDesign(designs.designs);
                        setTimeout(() => {
                          redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                        }, 1000);
                      })
                      .catch((e) => {
                        setProgress(0);
                      });
                  }, 2000);
                });
              } else {
                axiosHelper.updateDraft(design.id, data, true, '').then(async (response) => {
                  setProgress(85);
                  console.log(response);
                  setPortfolioDesign([]);
                  setTimeout(async () => {
                    await axiosHelper
                      .getDesignerDetails(loggedUser?.id)
                      ?.then(async (designs) => {
                        await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                          setUserAwards(awards.awards);
                        });
                        await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                          setUserInspirations(inspirations.inspirations);
                          setUserInspirationsCopy(inspirations.inspirations);
                        });
                        setPortfolioDesign(designs.designs);
                        setTimeout(() => {
                          redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                        }, 1000);
                      })
                      .catch((e) => {
                        setProgress(0);
                      });
                  }, 2000);
                });
              }
            } else {
              agreementError.current = true;
            }
          } else {
            designNameError.current = true;
          }
        } else {
          coverError.current = true;
        }
      }
    }
  };

  const handleName = (e: any) => {
    let nome = e.target.value;
    setDesignNameLength(nome.length);
    setProjName(e.target.value);
    if (e.target.value.length > 0) {
      designNameError.current = false;
    }
  };

  const handleAbstract = (e: any) => {
    let abstract = e.target.value;
    setDesignAbstractLength(abstract.length);
    setProjAbstract(e.target.value);
    if (e.target.value.length > 0) {
      abstractError.current = false;
    }
  };

  const handleDescription = (e: any) => {
    setProjDescr(e.target.value);
  };

  async function archivePresignedUrl(file: File) {
    console.log('get archive presigned');
    return await axiosHelper
      .getPresignedUrl(5, file.name, file.size, file.type)
      ?.then(async (response) => {
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  async function videoPresignedUrl(file: File) {
    return await axiosHelper
      .getPresignedUrl(6, file.name, file.size, file.type)
      ?.then(async (response) => {
        return await axios
          .put(response.data.payload, file)
          .then((risposta) => {
            console.log(risposta);
            updateString(response.data.id);
          })
          .catch((error) => {
            throw error;
          });
      })
      .catch((error) => {
        throw error;
      });
  }

  const removeImage = (imgNumber: number) => {
    if (design) {
      return axiosHelper.removeDraftImage(design.id, imgNumber).then((response) => {
        setCurrentDesign(response);
      });
    }
  };

  const removeVideo = () => {
    if (design) {
      return axiosHelper.removeDesignVideo(design.id).then((response) => {
        setProjVideo(undefined);
        setCurrentDesign(response);
      });
    }
  };

  const removeArchive = () => {
    if (design) {
      return axiosHelper.removeDesignArchive(design.id).then((response) => {
        setProjArchive(undefined);
        setCurrentDesign(response);
      });
    }
  };

  const updateString = async (string: string) => {
    console.log('update string ' + string);
    const stringa = string + ',';
    uuidString.current += stringa;
    console.log('MI HANNO PASSATO ' + string);
    console.log(uuidString.current);
    console.log(filesTot.current);

    const virgole = uuidString.current.split(',').length - 1;
    if (virgole === filesTot.current) {
      if (contest) {
        console.log('create archive');
        const uuids = uuidString.current.slice(0, -1);
        if (!draft) {
          console.log('NOT DRAFT');
          formData.current &&
            (await axiosHelper.createDesign(formData.current, contest?.id, uuids).then((response) => {
              console.log(response);
              setCurrentDesign(response);
              redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/gallery/${response.name}`);
            }));
        } else {
          console.log('update string DRAFT');
          if (formData.current && design) {
            console.log('update string ci sono formdata.current e design');
            console.log('uuids: ' + uuids);
            axiosHelper.updateDraft(design.id, formData.current, true, uuids).then((response) => {
              console.log('prima chiamata');
              console.log(response);
              setTimeout(async () => {
                console.log('update string dopo prima chiamata');
                await axiosHelper.getDesignerDetails(loggedUser?.id)?.then(async (designs) => {
                  await axiosHelper.getDesignerAwards(loggedUser?.id)?.then((awards) => {
                    setUserAwards(awards.awards);
                  });
                  await axiosHelper.getDesignerInspirations(loggedUser?.id)?.then((inspirations) => {
                    setUserInspirations(inspirations.inspirations);
                    setUserInspirationsCopy(inspirations.inspirations);
                  });
                  setPortfolioDesign(designs.designs);
                  setTimeout(() => {
                    redirect(`/${ROUTEPATHS.COMMUNITY}/${loggedUser?.nameForURL}/portfolio`);
                  }, 1000);
                });
              }, 2000);
            });
          }
        }
      }
    }
  };

  // const updateStringDraft

  const checkHowManyDesignImages = () => {
    let number = 0;
    if (coverImage) {
      number++;

      if (designImages.length > 0) {
        // console.log(designImages.length);
        number += designImages.length;
      }
    }
    console.log(number);
    return number;
  };

  async function imagesPresignedUrl(files: File[]) {
    let filesArr = files;
    console.log('images presigned entro');
    console.log(filesArr.length);
    // console.log(checkHowManyDesignImages());
    filesArr.map((item: File, index: number) => {
      // if (checkHowManyDesignImages() > 1) {
      axiosHelper
        .getPresignedUrl(checkHowManyDesignImages() + index, item.name, item.size, item.type)
        ?.then((response) => {
          // console.log(response.data.payload);
          console.log('images presigned response : ' + index);
          axios
            .put(response.data.payload, item, { headers: {} })
            .then((risposta) => {
              console.log('images presigned putto : ' + response.data.id);
              console.log(risposta);
              updateString(response.data.id);
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
      // }
    });
  }

  const goToDetail = async (userid: number) => {
    console.log(userid);
    try {
      let result: User = await axiosHelper.retrieveUserById(userid);
      console.log(result);

      setCommunityUser(result);
      if (communityUser) {
        redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const isUpperCase = (string: string) => /^[AZ]*$/.test(string);

  return (
    <div className="bg-light-gray">
      <AppHeader
        links={headerMenu}
        bgColor="#eab95a"
        logoImg={headerLogo}
        bgImage={headerimg}
        alpha={0.4}
        breadcrumbs={`Community/${communityUser?.username}/portfolio`}
        pageTitle={`${design?.name}`}
      />
      <PageNavigationBrief
        bgColor="#E3E3E3"
        links={briefNavPages}
        hideClientGallery={false}
        // hideClientGallery={contest?.hideGalleryTab}
        hideAwardsTab={contest?.hideAwardsTab}
        hideInspirationTab={contest?.hideInspirationTab}
        clientlogged={loggedUser?.userType === 'C' && loggedUser.id === contest?.userId ? true : false}
        closed={contest?.isClosed}
        private={false}
        // private={contest?.isPrivate}
      />

      <main className={`container pt-5 mb-5 overflow-hidden `}>
        {/* Head ====================================================================== */}
        {/* <div className="row ">
          {contest?.bannerImage ? (
            <div className="col-12  px-3">
              <ProfileHeaderContest bgImage={contest?.bannerImage} />
            </div>
          ) : null}
        </div> */}
        <div className="row px-4 px-sm-1  ">
          <div className="col-12 col-md-3 text-center mx-3 mx-sm-0  d-flex align-items-center justify-content-sm-center justify-content-md-start ">
            <div className="position-relative ">
              <img
                style={{ width: '270px', height: '270px' }}
                className="img-fluid rounded mt-5 "
                src={contest?.image}
                alt="Placeholder"
              />

              <MultiActionButtonsWrapper position="bottom-left" rounded>
                <ActionButton label="Private gallery" icon="private" />
              </MultiActionButtonsWrapper>
            </div>
          </div>
          <div className="col-12 col-md-9 px-4 pt-5">
            <div className="row">
              <div className="info-main mb-5 px-3 px-sm-4 col-12">
                <h1 className="sub-title-brief  color-dark">
                  {/* {() => console.log(isUpperCase(contest!.userName[0]))} */}
                  {finalString(contest?.name)}{' '}
                </h1>
                <div className="text-16 color-dark">{finalString(contest?.category)}</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 px-3 px-sm-4 col-md-3">
                <div className="info">
                  <div className="d-flex justify-content-start mb-3" style={{ height: '20px', overflow: 'hidden' }}>
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faIndustry as IconProp} className=" text color-blue me-2" />
                    </div>
                    <div className="text" style={{ cursor: 'pointer' }} onClick={() => goToDetail(contest!.userId)}>
                      {contest?.userName != null
                        ? finalSlicedString(contest?.userName)
                        : finalString(contest?.linkToClient)}
                    </div>
                  </div>
                  <div className="d-flex  justify-content-start mb-3">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text">{contest?.city + ', ' + contest?.countryName}</div>
                  </div>
                  <div className="d-flex  justify-content-start">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />
                    </div>
                    <div className="text ">Ends {contest ? registrationDateString(contest.endUploadDate) : null}</div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-9  mt-5 mt-md-0">
                {contest?.contestType !== 'Co-creation' && (
                  <ContestStats
                    entries={contest?.entries}
                    inspirations={numberVotes(contest?.inspirationCount)}
                    awards={contest?.prices}
                    votes={numberVotes(contest?.sumVotesCount)}
                  />
                )}
                {contest?.contestType === 'Co-creation' && (
                  <ContestStats
                    entries={cocreationstats?.entries}
                    inspirations={cocreationstats?.inspirations}
                    awards={cocreationstats?.awards}
                    votes={cocreationstats?.votes}
                  />
                )}
              </div>
              <div className=" d-none d-md-flex">
                <div className="container  ">
                  <TimeLine
                    progress={contest?.endVotingDate ? tlUCC : tlUC}
                    contest={contest}
                    cocreationcontest={allcocoreations}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Body ====================================================================== */}
        {userLogged && checkUser() && (
          <div className="row ">
            <div className="col-12 col-md-3 px-5 px-sm-3 position-relative">
              <SideActionsCard>
                <button className="btn-blue rounded full mb-3">Add to favorite</button>
                <button className="btn-blue rounded full mb-3">Have a question?</button>
                <MiniTimeLine progress={minitl1} contest={contest} cocreationcontest={allcocoreations} />

                <button className="btn-success big full  rounded" style={{ height: '45px' }}>
                  <div className="btn-icon  ">
                    <FontAwesomeIcon
                      icon={faCloudArrowUp as IconProp}
                      style={{ fontSize: '3rem' }}
                      className="text color-light "
                    />
                  </div>
                  <div className="">Upload</div>
                </button>
              </SideActionsCard>
              <div className="tags mt-7 d-flex flex-wrap">
                {contestTag &&
                  contestTag.map((item: ContestTag, index: number) => (
                    // console.log(item),
                    <>
                      <button className="btn-tag mb-4 me-4" key={index}>
                        <span>{item.name.replace(',', '')}</span>
                      </button>
                    </>
                  ))}
                {/* <button className="btn-tag mb-4 me-4">
                <span>Even more Looooong stuff</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>Short</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The</span>
              </button>
              <button className="btn-tag  mb-4">
                <span>The medium</span>
              </button> */}
              </div>

              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://desall.com/contest/${contest?.nameForUrl}/gallery/${design?.nameForURL}`}
                  target="_blank"
                >
                  <button className="btn-social__facebook full rounded mb-3 ">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faFacebook as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">like</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://twitter.com/share?url=https://desall.com/contest/${contest?.nameForUrl}/gallery/${design?.nameForURL}`}
                  target="_blank"
                >
                  <button className="btn-social__twitter full rounded mb-3">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faXTwitter as IconProp} className="text color-light" />
                      {/* <img src={xLogo} className="" style={{ height: '15px', width: '15px' }} /> */}
                    </div>
                    <span className="text-lowercase">post</span>
                  </button>
                </a>
              </div>
              <div className="col-12 col-md-6   position-relative">
                <a
                  href={`http://pinterest.com/pin/create/button/?url=desall.com/contest/${
                    contest?.nameForUrl
                  }/gallery/${design?.nameForURL}${
                    design?.image1Attachment ? '&media=${design?.image1Attachment.s3Path}' : ''
                  }&description=${design?.dslUser?.username} on Desall.Check it out on Desall.`}
                  target="_blank"
                >
                  <button className="btn-social__pinterest full rounded mb-3">
                    <div className="btn-icon">
                      <FontAwesomeIcon icon={faPinterest as IconProp} className="text color-light" />
                    </div>
                    <span className="text-lowercase">save</span>
                  </button>
                </a>
              </div>
            </div>

            <div className="col-12 col-md-9 pt-5 px-5 px-sm-4">
              <div className="row">
                <div className="col-12 px-4">
                  <div className="row-mb-5">
                    <div className="col-12 col-md-2 mb-3"></div>
                    <div className="col-12 col-md-10"></div>
                  </div>
                </div>
              </div>
              {contest?.contestType === 'Naming' && design && (
                <EditDraftNaming
                  id={design?.id}
                  name={design?.name}
                  payoff={design?.payoff}
                  description={design?.textDescription}
                />
              )}
              {design?.contest?.contestType.name !== 'Co-creation' &&
                design?.contest?.contestType.name !== 'Naming' && (
                  <>
                    <div className="row">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-12 px-4">
                          <div className="row mb-5">
                            <div className="col-12 col-md-2 mb-3"></div>
                            <div className="col-12 col-md-10 text  py-5">
                              <i>
                                To upload your design, you have to fill the mandatory fields (
                                <span className="color-red">*</span>)
                              </i>
                            </div>
                            <div
                              className="col-12 col-md-2  text fw-bold  mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ position: 'relative', zIndex: 10 }}
                            >
                              <div className=" col-11 ">
                                Project name <span className="color-red me-1">*</span>
                              </div>
                              <div className="ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0"
                                  onMouseEnter={() => setPopUpProjName(true)}
                                  onMouseLeave={() => setPopUpProjName(false)}
                                />
                              </div>
                              <div className={`${popUpProjName ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>no text</i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10">
                              <input
                                id="designname"
                                className={`${styles.designName}`}
                                type="text"
                                value={projName}
                                {...register('designName', { onChange: handleName, maxLength: 50 })}
                                placeholder="Identify with a name your project "
                              />
                              <div className="row mt-2">
                                <div className="col-1"></div>
                                <div className="col-10">
                                  {designNameError.current && (
                                    <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />
                                      &nbsp; Please provide a &nbsp; <b> Project name. </b>
                                    </span>
                                  )}
                                </div>
                                <div className="col-1  d-flex justify-content-end">
                                  {<span>{designNameLength}/50</span>}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text fw-bold  mb-3 d-flex  flex-row ps-md-1 ps-2 "
                              style={{ position: 'relative', zIndex: 10 }}
                            >
                              <div className="col-11">Design privacy</div>
                              <div className="ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0"
                                  onMouseEnter={() => setPopUpPrivacy(true)}
                                  onMouseLeave={() => setPopUpPrivacy(false)}
                                />
                              </div>
                              <div className={`${popUpPrivacy ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>
                                    This option allows you to keep your entry hidden from the other participants until
                                    the start of the Community Vote. You can activate this option only if you publish
                                    your project within the first half of the Upload phase. This option is not available
                                    for private gallery contests.
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10">
                              <input
                                type="radio"
                                className="privacy align-middle me-4"
                                checked={!designPrivacy}
                                disabled={!hiddenOption()}
                                onClick={() => hiddenOption() && setDesignPrivacy(false)}
                              />
                              <span className={` me-5 text ${contest?.isPrivate === true ? 'color-dark_grey_70' : ''}`}>
                                Visible
                              </span>
                              &nbsp;
                              <input
                                type="radio"
                                className="privacy align-middle me-4"
                                checked={designPrivacy}
                                disabled={!hiddenOption()}
                                onClick={() => hiddenOption() && setDesignPrivacy(true)}
                              />
                              <span className={` me-5 text ${contest?.isPrivate === true ? 'color-dark_grey_70' : ''}`}>
                                Hidden
                              </span>
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">
                                Abstract <span className="color-red">*</span>
                              </div>
                              <div className=" ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpAbstract(true)}
                                  onMouseLeave={() => setPopUpAbstract(false)}
                                />
                              </div>
                              <div className={`${popUpAbstract ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <i>Explain in some words your project</i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 text-center">
                              <textarea
                                placeholder="Explain in some world your project"
                                className={`${styles.abstract}`}
                                value={projAbstract}
                                {...register('abstract', {
                                  onChange: handleAbstract,
                                  maxLength: 250,
                                })}
                              />

                              <div className="row ">
                                <div className="col-sm"></div>
                                <div className="col-sm">
                                  {abstractError.current && (
                                    <span className=" d-flex flex-row justify-content-center align-items-center color-dark_red">
                                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />
                                      &nbsp; Please provide an &nbsp;<b>Abstract</b>
                                    </span>
                                  )}
                                </div>
                                <div className="col-sm d-flex justify-content-end">
                                  {<span>{designAbstractLength}/250</span>}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row mb-5 ">
                            <div
                              className="col-12 col-md-2 text fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">Description</div>
                              <div className=" ml-auto ">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpDescription(true)}
                                  onMouseLeave={() => setPopUpDescription(false)}
                                />
                              </div>
                              <div className={`${popUpDescription ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} p-4 text`}>
                                  <i>
                                    Describe your design in a deep way. Tell us the reason of your choice, motivations
                                    and everything you think could be useful to understand your project
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10">
                              <textarea
                                value={projDescr}
                                {...register('description', { onChange: handleDescription })}
                                placeholder="Describe your design in a deep way. Tell us the reason of you choice, motivation and everything you think coul be uwseful to understanf your project"
                              />
                            </div>
                          </div>

                          <div className={`row  ${!coverError.current && 'mb-5'}`}>
                            <div
                              className="col-12 col-md-2 text fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">
                                Cover Image <br />
                                file <span className="color-red">*</span>
                              </div>
                              <div className="ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpCover(true)}
                                  onMouseLeave={() => setPopUpCover(false)}
                                />
                                <div className={`${popUpCover ? styles.message : styles.noMessage}`}>
                                  <div className={`  ${styles.freccetta} `}></div>
                                  <div className={`${styles.infoActions} text p-4`}>
                                    <i>NO TEXT </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className={`col-12 col-md-10  d-flex flex-column align-items-center justify-content-center  ${styles.uploadCoverContainer}`}
                            >
                              {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                              <Dropzone
                                onDrop={onDropCover}
                                accept={accept}
                                maxSize={31457280}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <section
                                    className={`${styles.dropzone} bg-light d-flex align-items-center  justify-content-center`}
                                  >
                                    <div
                                      className="col-12 d-flex h-100 flex-column align-items-center justify-content-center text-center"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <span>
                                        Drag and drop an image or <span className="color-blue">choose a file</span> from
                                        your computer <br />
                                        (allowed formats: .jpg, .png)
                                      </span>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                            <div className={`d-flex  ${coverError.current && 'mb-5'} `}>
                              <div className="col-2 "></div>
                              <div className="col-10   d-flex flex-row justify-content-center">
                                {coverError.current && (
                                  <span className="color-dark_red ">
                                    <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />{' '}
                                    &nbsp; please provide a <b>Cover image</b>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className={`row  mb-7 ${uploadedCoverImage || coverImage ? '' : 'd-none'}`}>
                            <div className="col-12 col-md-2 text fw-bold mb-3">
                              <div className=" d-flex justify-content-between "></div>
                            </div>
                            <div
                              className={`col-12 col-md-10  d-flex flex-column align-items-center justify-content-center `}
                            >
                              {uploadedCoverImage && (
                                <div className=" d-flex" style={{ width: '100%' }}>
                                  <div className={`${styles.previewImg}`}>
                                    <img
                                      src={URL.createObjectURL(uploadedCoverImage)}
                                      className={`${styles.previewImg}`}
                                    />
                                  </div>
                                  <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                    <span className="text-xl">{uploadedCoverImage.name}</span>
                                  </div>
                                  <div className=" d-flex align-items-center justify-content-end ">
                                    <div
                                      className={` ${styles.removeButton}`}
                                      onClick={() => setUploadedCoverImage(undefined)}
                                    >
                                      <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {coverImage && (
                                <div className=" d-flex" style={{ width: '100%' }}>
                                  <div className={`${styles.previewImg}`}>
                                    <img src={coverImage.s3Path} className={`${styles.previewImg}`} />
                                  </div>
                                  <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                    <span className="text-xl">{coverImage.originalFileName}</span>
                                  </div>
                                  <div className=" d-flex align-items-center justify-content-end ">
                                    <div className={` ${styles.removeButton}`} onClick={() => removeCurrentCover()}>
                                      <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">Image file</div>
                              <div className="ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpImages(true)}
                                  onMouseLeave={() => setPopUpImages(false)}
                                />
                                <div className={`${popUpImages ? styles.message : styles.noMessage}`}>
                                  <div className={`  ${styles.freccetta} `}></div>
                                  <div className={`${styles.infoActions} text p-4`}>
                                    <i>NO TEXT </i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className={`col-12 col-md-10 ${
                                uploadedImages.length + designImages.length > 0 ? '' : 'mb-5'
                              } d-flex flex-column align-items-center justify-content-center  ${
                                styles.uploadImagesContainer
                              }`}
                            >
                              {/* <label
                              htmlFor="images"
                              className={`${styles.dropcontainercoverimages} d-flex position-relative flex-column justify-content-center align-items-center text-center px-5`}
                            > */}
                              <Dropzone onDrop={onDrop} accept={accept} maxSize={31457280}>
                                {({ getRootProps, getInputProps }) => (
                                  <section
                                    className={`${styles.dropzone} bg-light d-flex align-items-center justify-content-center`}
                                  >
                                    <div
                                      className="col-12  h-100 d-flex flex-column align-items-center justify-content-center text-center"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <span>
                                        Drag and drop an image or <span className="color-blue">choose a file</span> from
                                        your computer <br />
                                        (allowed formats: .jpg, .png). Max 4 images.
                                      </span>
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                          </div>

                          <div
                            className={`row mb-5 ${uploadedImages.length + designImages.length > 0 ? '' : 'd-none'} `}
                          >
                            <div className="col-12 col-md-2 text fw-bold mb-3 ">
                              <div className=" d-flex justify-content-between  "></div>
                            </div>
                            <div
                              className={`col-12 col-md-10 mb-5 d-flex  flex-column align-items-center justify-content-center  `}
                            >
                              {designImages?.map((item, index) => (
                                <div className="mt-2 d-flex" style={{ width: '100%' }}>
                                  <div className={`${styles.previewImg}`}>
                                    <img
                                      src={item.s3Path}
                                      key={index}
                                      className={`${styles.previewImg}`}
                                      style={{ height: '100%' }}
                                    />
                                  </div>
                                  <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                    <span className="text-xl">{item.originalFileName}</span>
                                  </div>
                                  <div className=" d-flex align-items-center justify-content-end ">
                                    <div className={`${styles.removeButton}`} onClick={() => deleteDesignImg(index)}>
                                      <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {uploadedImages?.map((item, index) => (
                                <div className="mt-2 d-flex" style={{ width: '100%' }}>
                                  <div className={`${styles.previewImg}`}>
                                    <img
                                      src={URL.createObjectURL(item)}
                                      key={index}
                                      className={`${styles.previewImg}`}
                                      style={{ height: '100%' }}
                                    />
                                  </div>
                                  <div className="d-flex flex-row w-100 align-items-center justify-content-center ">
                                    <span className="text-xl">{item.name}</span>
                                  </div>
                                  <div className=" d-flex align-items-center justify-content-end ">
                                    <div className={`${styles.removeButton}`} onClick={() => deleteImg(index)}>
                                      <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text  fw-bold mb-3  d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">Archive file</div>
                              <div className=" ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpArchive(true)}
                                  onMouseLeave={() => setPopUpArchive(false)}
                                />
                              </div>
                              <div className={`${popUpArchive ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} p-4 text`}>
                                  <i>
                                    use the archive to upload client required files and/or extra content <br /> allowed
                                    format: .zip; max file size: 100 MB
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 mb-5">
                              <label
                                htmlFor="archive"
                                className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}
                              >
                                <label className="drop-title fw-normal">
                                  <input
                                    type="file"
                                    id="archive"
                                    accept=".zip"
                                    className={`${styles.inputfile}`}
                                    required={false}
                                    multiple={true}
                                    onChange={handleArchiveSelect}
                                  />
                                  <span className="color-primary fw-normal" style={{ color: '#7997c5' }}>
                                    Choose a file&nbsp;
                                  </span>
                                  <span>from your computer (allowed format: .zip max file size: 100 MB).</span>
                                </label>
                              </label>
                              <div className={`row  ${archiveFile || projArchive ? 'mt-5' : 'd-none'} `}>
                                {archiveFile && (
                                  <div className=" d-flex mt-2 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon
                                          icon={faFileZipper as IconProp}
                                          className=" text me-0 color-light"
                                          style={{ fontSize: '2rem' }}
                                        />
                                      </div>
                                    </div>
                                    <div className=" text-center">{archiveFile.name} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div
                                        className={`${styles.removeButton}`}
                                        // style={{ borderRadius: '8px', height: '50px', width: '50px' }}
                                        onClick={() => setArchiveFile(undefined)}
                                      >
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {projArchive && (
                                  <div className=" d-flex mt-2 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon
                                          icon={faFileZipper as IconProp}
                                          className=" text me-0 color-light"
                                          style={{ fontSize: '2rem' }}
                                        />
                                      </div>
                                    </div>
                                    <div className=" text-center">{projArchive.originalFileName} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div className={`${styles.removeButton}`} onClick={() => removeArchive()}>
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text ps-1 fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">Video file</div>
                              <div className=" ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpVideo(true)}
                                  onMouseLeave={() => setPopUpVideo(false)}
                                />
                              </div>
                              <div className={`${popUpVideo ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} p-4 text`}>
                                  <i>
                                    use the archive to upload client required files and/or extra content <br /> allowed
                                    format: .zip; max file size: 50 MB
                                  </i>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 mb-5">
                              <label
                                className={`${styles.dropcontainerarchive} d-flex position-relative flex-column justify-content-center px-5`}
                              >
                                <label className="drop-title  fw-normal">
                                  <input
                                    type="file"
                                    id="video"
                                    accept=".zip"
                                    className={`${styles.inputfile}`}
                                    required={false}
                                    multiple={false}
                                    onChange={handleVideoSelect}
                                  />
                                  <span className="color-primary fw-normal" style={{ color: '#7997c5' }}>
                                    Choose a file&nbsp;
                                  </span>
                                  <span>from your computer (allowed format: .zip max file size: 50 MB).</span>
                                </label>
                              </label>
                              <div className={`row  ${videoFile || projVideo ? 'mt-5' : 'd-none'} `}>
                                {videoFile && (
                                  <div className=" d-flex mt-2 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon
                                          icon={faFileVideo as IconProp}
                                          className=" text me-0 color-light"
                                          style={{ fontSize: '2rem' }}
                                        />
                                      </div>
                                    </div>
                                    <div className=" text-center">{videoFile.name} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div className={`${styles.removeButton}`} onClick={() => setVideoFile(undefined)}>
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {projVideo && (
                                  <div className=" d-flex mt-2 justify-content-between align-items-center">
                                    <div className="d-flex w-100 align-items-center">
                                      <div className={`bg-blue ${styles.filezip} `}>
                                        <FontAwesomeIcon
                                          icon={faFileVideo as IconProp}
                                          className=" text me-0 color-light"
                                          style={{ fontSize: '2rem' }}
                                        />
                                      </div>
                                    </div>
                                    <div className=" text-center">{projVideo.originalFileName} </div>
                                    <div className="d-flex w-100 align-items-center justify-content-end ">
                                      <div
                                        className={`${styles.removeButton}`}
                                        // style={{ borderRadius: '8px', height: '50px', width: '50px' }}
                                        onClick={() => removeVideo()}
                                      >
                                        <FontAwesomeIcon icon={faXmark as IconProp} className=" text-16 me-0" />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text ps-1 fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">
                                Keyword <span className="color-red me-1"> * </span>
                              </div>
                              <div className="ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpTags(true)}
                                  onMouseLeave={() => setPopUpTags(false)}
                                />
                                <div className={`${popUpTags ? styles.message : styles.noMessage}`}>
                                  <div className={`  ${styles.freccetta} `}></div>
                                  <div className={`${styles.infoActions} text p-4`}>
                                    <i>NO TEXT </i>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-10 ">
                              <input
                                type="text"
                                value={tagString}
                                style={{ height: '30px' }}
                                {...register('tempTags', {
                                  onChange: (e) => tagstring(e.target.value),
                                })}
                              />
                              {tagsError.current && (
                                <span className=" d-flex text flex-row justify-content-center align-items-center color-dark_red">
                                  <FontAwesomeIcon icon={faCircleExclamation as IconProp} className=" text me-0" />{' '}
                                  &nbsp; Please provide &nbsp; <b> keywords. </b>
                                </span>
                              )}
                              {suggestionArray.length > 0 && tagStringCheck() && (
                                <div className={`${styles.suggestionWrapper}`}>
                                  {suggestionArray.map((item: DSLTag, index: number) => (
                                    <div
                                      className={`text ${styles.suggestionRow}`}
                                      key={index}
                                      onClick={() => addTag(item.name)}
                                    >
                                      {item.name}
                                    </div>
                                  ))}
                                </div>
                              )}
                              {/* <span className="  color-dark_grey_40">please separate each tag with spaces</span> */}

                              <div className="mt-3">
                                {/* {console.log(tagsArray)} */}
                                {tagsArray.map(
                                  (item: string, index: number) =>
                                    item !== '' && (
                                      <button disabled className="btn-tag" key={index}>
                                        <span>{item}</span>
                                      </button>
                                    )
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row mb-5">
                            <div
                              className="col-12 col-md-2 text ps-1 fw-bold mb-3 d-flex flex-row ps-md-1 ps-2 "
                              style={{ zIndex: '10', position: 'relative' }}
                            >
                              <div className="col-11">
                                Agreement <span className="color-red me-1"> * </span>
                              </div>
                              <div className=" ml-auto">
                                <FontAwesomeIcon
                                  icon={faCircleQuestion as IconProp}
                                  className=" text color-blue me-0 "
                                  onMouseEnter={() => setPopUpAgreement(true)}
                                  onMouseLeave={() => setPopUpAgreement(false)}
                                />
                              </div>
                              <div className={`${popUpAgreement ? styles.message : styles.noMessage}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} p-4 text`}>
                                  <i>no text</i>
                                </div>
                              </div>
                              {/* <div className={`${agreementError.current ? styles.messageDown : styles.noMessageDown}`}>
                                <div className={`  ${styles.freccetta} `}></div>
                                <div className={`${styles.infoActions} text p-4`}>
                                  <div
                                    className="bg-warning d-flex flex-row align-items-center justify-content-center me-3"
                                    style={{ height: '30px', width: '30px' }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faExclamation as IconProp}
                                      className="color-light "
                                      style={{ fontSize: '1.5rem', fontWeight: 'bolder' }}
                                    />
                                  </div>
                                  Seleziona questa casella se intendi procedere.
                                </div>
                              </div> */}
                            </div>
                            <div className="col-12 col-md-10 mb-5">
                              <div className="d-flex flex-row justify-content-start">
                                <input
                                  type="checkbox"
                                  checked={agreement}
                                  style={{ height: '30px', width: '30px' }}
                                  onChange={() => (
                                    setAgreement(!agreement),
                                    (agreementError.current = false),
                                    checkHowManyDesignImages()
                                  )}
                                />
                                <div className=" w-100 d-flex flex-row align-items-center justify-content-between">
                                  <label className=" ms-3 fw-normal">
                                    I'm over 18 and agree to the
                                    <a target="_blank" href={contest?.termsAttachment && contest.termsAttachment}>
                                      &nbsp; Contest agreement
                                    </a>
                                  </label>
                                  {agreementError.current && (
                                    <span className="d-flex flex-row align-items-center justify-content-center color-dark_red">
                                      <FontAwesomeIcon icon={faCircleExclamation as IconProp} className="me-1" />
                                      You must accept the conditions
                                      {/* <b className="ms-1">Contest Agreement</b> */}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-3"></div>
                          <div className="col-12 col-md-3 mb-4 px-5 align-items-center ">
                            <div className="d-flex flex-row justify-content-center ">
                              {progress === 0 && (
                                <button
                                  className={`btn-blue rounded full`}
                                  onClick={() => (setDraft(true), setUpload(true))}
                                >
                                  Save draft
                                </button>
                              )}
                              {progress !== 0 && !draft && (
                                <button className={`btn-blue rounded full`} onClick={() => setDraft(true)}>
                                  Save draft
                                </button>
                              )}
                              {progress !== 0 && draft && (
                                <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                                  <TailSpin
                                    height="15"
                                    width="15"
                                    color="#7997c5"
                                    wrapperStyle={{}}
                                    wrapperClass="me-2"
                                    visible={true}
                                    ariaLabel="tail-spin-loading"
                                  />
                                  &nbsp;
                                  <span>Uploading</span>
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-md-3 mb-4 px-5 align-items-center">
                            <div className="d-flex flex-row justify-content-center ">
                              {progress === 0 && (
                                <button
                                  className="btn-success rounded full"
                                  onClick={() => (setDraft(false), canPublishDraft())}
                                >
                                  Publish
                                </button>
                              )}
                              {progress !== 0 && draft && <button className="btn-success rounded full">Publish</button>}
                              {progress !== 0 && !draft && (
                                <button className="btn-dark_grey_80 d-flex rounded full " disabled>
                                  <TailSpin
                                    height="15"
                                    width="15"
                                    color="#7997c5"
                                    wrapperStyle={{}}
                                    wrapperClass="me-2"
                                    visible={true}
                                    ariaLabel="tail-spin-loading"
                                  />
                                  &nbsp;
                                  <span>Uploading</span>
                                </button>
                              )}
                            </div>
                            <PopUpPublishDraft
                              popup={popUpPublishDraft}
                              close={() => (setUpload(true), setPopUpPublishDraft(false), onSubmit)}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              {contest?.contestType === 'Co-creation' &&
                cocreationContests &&
                cocreationContests.map((item: CoCreationContest, index: number) => (
                  <>
                    <UploadContestCoCreation contest={item} />
                  </>
                ))}
            </div>
          </div>
        )}

        {!userLogged && <PopUpLogin close={() => setPopup(false)} popup={popup} login={(e: boolean) => setPopup(e)} />}
      </main>

      {/* <div className='rounded' style={{transform:'rotate(45deg) translate(75px, 35px) ', backgroundColor:'grey', height:'50px', width:'50px'}}></div>
      <div className='rounded mx-5' style={{height:'100px', width:'250px', backgroundColor:'grey' }}></div> */}
      <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} />
    </div>
  );
};

export default EditDraft;

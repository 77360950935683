import React, { useEffect, useState } from 'react';
import styles from './TimeLine.module.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faIndustry, faUsers, faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';

import { IPhase, ITimeLine } from './timelineInterfaces';
import TLHLP from './timeline.helper';

import Timer from './components/Timer';
import ProgressSection from './components/ProgressSection';
import { Datestr } from '../../helpers/utils';
import { useStoreState } from 'pullstate';
import DslStore from '../../store/DslStore';
import { getAllCoCreationContests, getCoCreationContests, getCurrentContest } from '../../store/Selectors';
import { CoCreationContest, Contest, TimelineData } from '../../global';
import { calculateTimelines } from '../../helpers/utilities';
import { time } from 'console';

interface TimelineWLFProps {
  contest: Contest;
}

const TimeLineWLF: React.FC<TimelineWLFProps> = ({ contest }) => {
  const [totalUpload, setTotalUpload] = useState(0);
  const [passedUpload, setPassedUpload] = useState(0);
  const [totalCommunity, setTotalCommunity] = useState(0);
  const [passedCommunity, setPassedCommunity] = useState(0);
  const [totalClient, setTotalClient] = useState(0);
  const [passedClient, setPassedClient] = useState(0);
  const [effectiveEndDate, setEffectiveEndDate] = useState('');

  const [uploadActive, setUploadActive] = useState(false);
  const [communityActive, setCommunityActive] = useState(false);
  const [clientActive, setClientActive] = useState(false);

  const [phaseStuff, setPhaseStuff] = useState<IPhase>();
  const [timelineData, setTimelineData] = useState<TimelineData>();

  useEffect(() => {
    if (contest) {
      setTimelineData(calculateTimelines(contest));
    }
  }, [contest]);

  useEffect(() => {
    if (timelineData && contest) {
      console.log(timelineData);
      if (contest.endVotingDate !== '') {
        console.log('primo');
        setPhaseStuff({
          upload: {
            total: timelineData!.contestTimelineTotUpload,
            passed: timelineData!.contestTimelinePassedUpload,
          },
          community: {
            total: timelineData!.contestTimelineTotCommunityVote,
            passed: timelineData!.contestTimelinePassedCommunityVote,
          },
          client: {
            total: timelineData!.contestTimelineTotClientVote,
            passed: timelineData!.contestTimelinePassedClientVote,
          },
        });
      } else {
        setPhaseStuff({
          upload: {
            total: timelineData!.contestTimelineTotUpload,
            passed: timelineData!.contestTimelinePassedUpload,
          },
          client: {
            total: timelineData!.contestTimelineTotClientVote,
            passed: timelineData!.contestTimelinePassedClientVote,
          },
        });
      }
    }
  }, [timelineData, contest]);

  useEffect(() => {
    const setContestEndDate = () => {
      const date = new Date().getTime();
      if (contest) {
        if (date > new Date(contest.startDate).getTime() && date < new Date(contest.endUploadDate).getTime()) {
          console.log('upload contest normale');
          setUploadActive(true);
          setClientActive(false);
          setCommunityActive(false);
          if (contest.endVotingDate) {
            setCommunityActive(!contest.isPrivate);
          }
          setTotalUpload(Math.trunc((new Date(contest.endUploadDate).getTime() - new Date(contest.startDate).getTime()) / 86400000));
          console.log('setto passed');
          console.log((date - new Date(contest.startDate).getTime()) / 86400000);
          setPassedUpload(Math.trunc((date - new Date(contest.startDate).getTime()) / 86400000));
          setEffectiveEndDate(contest.endUploadDate);
        } else if (contest.endVotingDate) {
          console.log('community vote contest');
          setCommunityActive(!contest.isPrivate);
          if (date > new Date(contest.endUploadDate).getTime() && date < new Date(contest.endVotingDate).getTime()) {
            console.log('community vote contest normale');
            setClientActive(false);
            setUploadActive(false);
            setTotalUpload(100);
            setPassedUpload(100);
            setTotalCommunity(Math.trunc((new Date(contest.endVotingDate).getTime() - new Date(contest.endUploadDate).getTime()) / 86400000));
            setPassedCommunity(Math.trunc((date - new Date(contest.endUploadDate).getTime()) / 86400000));
            setEffectiveEndDate(contest.endVotingDate);
          } else if (date > new Date(contest.endVotingDate).getTime()) {
            console.log('client vote contest normale');
            if (contest.hypotheticalEndDate) {
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);

              setTotalCommunity(100);
              setPassedCommunity(100);

              setClientActive(true);
              setTotalClient(Math.trunc((new Date(contest.hypotheticalEndDate).getTime() - new Date(contest.endVotingDate).getTime()) / 86400000));
              setPassedClient(Math.trunc((date - new Date(contest.endVotingDate).getTime()) / 86400000));
              setEffectiveEndDate(contest.hypotheticalEndDate);
            } else if (contest.isClosed) {
              setUploadActive(false);
              setTotalUpload(100);
              setPassedUpload(100);
              setTotalCommunity(100);
              setPassedCommunity(100);
              setClientActive(false);
              setTotalClient(100);
              setPassedClient(100);
            }
          }
        } else {
          console.log('BAH');
          if (contest.isClosed) {
            setUploadActive(false);
            setTotalUpload(100);
            setPassedUpload(100);
            setTotalCommunity(100);
            setPassedCommunity(100);
            setClientActive(false);
            setTotalClient(100);
            setPassedClient(100);
          } else {
            setClientActive(true);
            setUploadActive(false);
            setTotalUpload(100);
            setPassedUpload(100);
            setTotalClient(100);
            setPassedClient(50);
          }
        }
        // }
      }
    };
    if (contest) {
      setContestEndDate();
    }
  }, [contest]);

  const uploadProgress: number = (passedUpload / totalUpload) * 100;
  const communityProgress: number | undefined = totalCommunity && passedCommunity ? (passedCommunity / totalCommunity) * 100 : undefined;
  const clientProgress: number = (passedClient / totalClient) * 100;

  let isClosed: boolean = uploadProgress + (communityProgress ?? 100) + clientProgress === 300;

  return (
    <div className={`row ${styles.timelineContainer}`}>
      <div className="col-12">
        <div className={`row ${styles.timelineWrapper} ${isClosed && styles.closed} px-0 d-flex`}>
          <Timer
            leftDays={
              uploadActive && !communityProgress
                ? TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload + 1 })
                : communityActive && !uploadActive && !clientActive
                ? TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity + 1 })
                : clientActive
                ? 0
                : 0
            }
            // leftDays ={TLHLP.getLeftDays([props.progress])}
            closed={isClosed}
            endUploadDate={effectiveEndDate}
          />

          <div className={`col-3 g-0 flex-grow-1 ${styles.progressWrapper} ${styles.upload}  ${(communityProgress || clientActive) && styles.closed}`}>
            <ProgressSection
              progress={uploadProgress}
              label="Upload Phase"
              icon={faCloudArrowUp as IconProp}
              iconLabel="upload phase"
              daysLeft={TLHLP.getDaysLeft({ total: totalUpload, passed: passedUpload + 1 })}
              // active={TLHLP.isUploadActive(props.progress)}
              active={uploadActive && !communityProgress && !clientActive}
            />
          </div>

          {communityActive && (
            <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.community} ${isClosed && styles.closed} ${(clientActive || uploadActive) && styles.closed}`}>
              <ProgressSection
                progress={uploadProgress > 99 ? (passedCommunity * 100) / totalCommunity : 0}
                label="Vote"
                icon={faUsers as IconProp}
                iconLabel="community vote"
                daysLeft={TLHLP.getDaysLeft({ total: totalCommunity, passed: passedCommunity + 1 })}
                active={communityActive && !uploadActive && !clientActive}
              />
            </div>
          )}
          {!clientActive && phaseStuff && (
            <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.client}  ${(TLHLP.isUploadActive(phaseStuff) || TLHLP.isCommunityactive(phaseStuff)) && styles.closed}`}>
              <ProgressSection
                progress={clientProgress}
                label="Client Vote"
                icon={faIndustry as IconProp}
                iconLabel="client vote"
                daysLeft={TLHLP.getDaysLeft({ total: totalClient, passed: passedClient + 1 })}
                active={false}
                pero={isClosed ? 1 : 0}
              />
            </div>
          )}
          {clientActive && (
            <div className={`col-2 g-0 ${styles.progressWrapper} ${styles.client} ${isClosed && styles.closed}`}>
              <ProgressSection
                progress={clientProgress}
                label="Client Vote"
                icon={faIndustry as IconProp}
                iconLabel="client vote"
                daysLeft={0}
                active={clientActive}
                pero={isClosed ? 1 : 0}
              />
            </div>
          )}
          <div className={`col flex-shrink-1 ${styles.progressWrapper} ${styles.endSection} m-0 p-0`}>
            {isClosed && <ProgressSection progress={100} label="Closed" closed={true} daysLeft={0} active={true} pero={1} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeLineWLF;

import React, { useEffect, useState } from 'react';

//Header Data

import styles from '../Profile.module.scss';

import { Award, User } from '../../../global';

import { fullPathLogoUser } from '../../../helpers/utils';
import { UserAwardRow } from '../../../components/Contest/AwardRow/AwardRow';
import DesignDefaultImg from '../../../assets/Design.png';
import { NoContent } from '../../../components/Reusable/NoContent';
import axiosHelper from '../../../helpers/axiosHelper';
import useSWR from 'swr';
import ProfileColumn from './components/ProfileColumn';

interface Props {
  user: User;
  loggedUser: User | null;
  breadcrumbs: (breadcrumbs: string) => void;
  setPopupMessage: (e: boolean) => void;
}

const AwardsWLF: React.FC<Props> = ({ user, loggedUser, breadcrumbs, setPopupMessage }) => {
  const [userAwards, setUserAwards] = useState<Award[]>([]);
  const { data, error, isLoading } = useSWR('ud|' + user.id, axiosHelper.userDetailsFetcher);

  const [awards, setAwards] = useState<Award[]>([]);
  useEffect(() => {
    if (data) {
      setUserAwards(data.awards);
    }
  }, [data]);

  useEffect(() => {
    breadcrumbs('Awards');
  }, []);

  useEffect(() => {
    console.log(userAwards);
    setAwards(userAwards);
  }, [userAwards]);

  return (
    <div className="bg-light-gray">
      <main className={`${styles.profileWrapper} container pt-5 mb-5 px-sm-0 overflow-hidden`}>
        <div className={`row    `}>
          {/* Left column  ====================================================== */}
          <ProfileColumn user={user} />
          <div className="col-12 col-md-9  ps-5 ps-sm-4">
            {awards && awards.length > 0 ? (
              awards?.map((UserAward: Award, index: number) => (
                <div key={index} className="row mb-4 mx-0">
                  <UserAwardRow
                    contestAward={UserAward}
                    image={UserAward.design.imageAttachment !== null ? fullPathLogoUser(UserAward.design.imageAttachment.s3Path) : DesignDefaultImg}
                  />
                </div>
              ))
            ) : (
              <NoContent />
            )}
          </div>
          {/* <div className="row mt-3 px-4"></div> */}
        </div>
      </main>
      {/* <AppFooter links={footerMenu} bgColor="#304053" logoImg={headerLogo} bgImage={footerImg} /> */}
    </div>
  );
};
export default AwardsWLF;

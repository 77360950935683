import React, { useEffect, useState } from 'react';
import { ICardInfo } from '../../Community/CardInfo/CardInfo';
import SquaredImage from '../../SquaredImage/SquaredImage';
import StatusBar from '../StatusBar/StatusBar';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { faTrophy, faIndustry, faPen } from '@fortawesome/free-solid-svg-icons';

import styles from './ContestCard.module.scss';
import ActionButton, { MultiActionButtonsWrapper } from '../../ActionButton/ActionButton';
import Spinner from '../../Spinner/spinner';
import { Contest, User } from '../../../global';
import DslStore, { setCoCreationContest, setCommunityUser, setContestTags, setCurrentContest } from '../../../store/DslStore';
import { useNavigate } from 'react-router-dom';
import axiosHelper from '../../../helpers/axiosHelper';
import { useStoreState } from 'pullstate';
import { getCurrentUser } from '../../../store/Selectors';
import { computeContestStatus, dateColorForStatus, getContestStatusText, progressNumber } from '../../../helpers/utilities';
import { ROUTEPATHS } from '../../../routes/routePaths';
import { useAuth } from '../../../context/AuthContext';
import { preload } from 'swr';

interface IContestCardWLF extends ICardInfo {
  contest: Contest;
  clickHandler?: (link: string, popupText: string) => void;
}

const ContestCardWLF: React.FC<IContestCardWLF> = ({ contest, clickHandler }) => {
  const redirect = useNavigate();
  const { loggedUser: user, isContestLiked, toggleContestLiked } = useAuth();
  const [dateColor, setDateColor] = useState(() => dateColorForStatus(contest));

  const goToDetail = async (userid: number) => {
    console.log(userid);
    let result: User = await axiosHelper.retrieveUserById(userid);
    console.log(result);
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label') {
      // setCommunityUser(result);
      redirect(`/${ROUTEPATHS.COMMUNITY}/${result.nameForURL}/Profile`);
    } else {
      // alert('link to other');
    }
  };

  const getCoCreationContestDetails = async (contestId: number) => {
    let cocreationContests = await axiosHelper.getCoCreationContests(contestId);
    if (cocreationContests) {
      console.log(cocreationContests);
      setCoCreationContest(cocreationContests);
    }
  };

  const getContestDetails = async (contestId?: number) => {
    if (contestId) {
      if (contest.contestType === 'Link to other') {
        if (clickHandler) {
          clickHandler(contest.linkToUrl, contest.linkDescription);
        }
      } else if (contest.contestType === 'White label') {
        if (clickHandler) {
          clickHandler('https://innovation.artsana.com/Contest/' + contest.nameForUrl + '/Brief', 'You will be redirected to the Artsana platform _innovation.artsana.com_');
        }
      } else {
        preload('contestname|' + contest.nameForUrl, axiosHelper.contestsFetcher);
        if (contest.contestType === 'Co-creation') {
          getCoCreationContestDetails(contestId);
        }
        redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_BRIEF}`);
      }
    }
  };

  const goToAwards = async () => {
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label') {
      redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_AWARDS}`);
    }
    // if (contest.id) {
    //   await axiosHelper.retrieveCompleteContest(contest.id).then((response) => {
    //     setCurrentContest(response.data);
    //   });
    // }
  };

  const goToContestGallery = async () => {
    if (contest.contestType !== 'Link to other' && contest.contestType !== 'White label') {
      redirect(`/${ROUTEPATHS.CONTEST}/${contest.nameForUrl}/${ROUTEPATHS.CONTEST_GALLERY}`);
    }
    // await axiosHelper.retrieveCompleteContest(contest.id).then(async (response) => {
    //   setCurrentContest(response.data);
    // });
  };
  return (
    <div className=" col-12  col-md-6 col-lg-3 py-3">
      <div className={`${styles.card}    col-12  d-flex flex-row align-items-center justify-content-center     position-relative`}>
        <div className={`${styles.cardInfoWrapper}  w-sm-75  d-flex flex-column  bg-light`}>
          <div className={`${contest.image ? styles.cardInfoImage : styles.cardImagePlaceholder} bg-light position-relative`} style={{ cursor: 'pointer' }}>
            <div
              onClick={() => {
                getContestDetails(contest.id);
              }}
            >
              {contest.image ? (
                <SquaredImage image={contest.image} />
              ) : (
                <div className={`${styles.squaredImageWrapper}`}>
                  <div className={`${styles.imageInner}`}>
                    <Spinner />
                  </div>
                </div>
                // <FontAwesomeIcon icon={faPencil as IconProp} className="color-light" />
              )}
            </div>
            {!isContestLiked(contest) && (
              <ActionButton
                label={user ? 'Save as favorite' : 'Login to save as favorite'}
                color={!user ? 'light-gray-3' : ''}
                icon="addFavorite"
                position="top-right"
                action={() => toggleContestLiked(contest)}
              />
            )}
            {isContestLiked(contest) && (
              <ActionButton label="Remove from favorites" icon="confirm" position="top-right" color="success" action={() => toggleContestLiked(contest)} />
            )}
            <MultiActionButtonsWrapper position="bottom-left">
              {contest.isPrivate && <ActionButton label="Private gallery" icon="private" />}
              {contest.accessCode && <ActionButton label="By invitation code contest" icon="accesscode" />}
              {contest.nda && <ActionButton label="Accept nda" icon="acceptnda" />}
              {!contest.isPrivate && <ActionButton label="Global contest" icon="global" />}
            </MultiActionButtonsWrapper>
          </div>
          <StatusBar status={computeContestStatus(contest)} progress={progressNumber(contest)} />
          <div className={`${styles.cardInfoContent} container bg-light `}>
            <div className="row h-25 mt-3 mb-3 mx-1 " onClick={() => getContestDetails(contest.id)}>
              <div className="col text-center align-self-center  ">
                <h3 className="fw-bold color-blue  d-none d-sm-block " style={{ cursor: 'pointer' }}>
                  {contest.name}
                </h3>
                <h1 className="fw-bold color-blue  d-sm-none d-sm-block">{contest.name}</h1>
              </div>
            </div>

            <div className=" col align-self-center text-center mb-3 ">
              {contest.contestType === 'Co-creation' ? 'Co-creation' : contest.category === 'Link to Other' ? 'Partnership' : contest.category}
            </div>

            <div className={`${styles.infoContainer} position-static  mt-2 pt-3 `}>
              <div className="justify-content-left mb-3   " style={{ height: '20px', overflow: 'hidden', cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faIndustry as IconProp} className=" text-left color-blue me-3" />
                <span className="justify-content-left  color-blue " onClick={() => goToDetail(contest.userId)}>
                  {contest.userName ?? contest.linkToClient}
                </span>
              </div>
              <div className=" text  justify-content-left mb-3 align-items-center d-flex flex-row">
                <FontAwesomeIcon icon={faCalendar as IconProp} className=" text color-blue me-3" />

                <span className={`mx-1 justify-content-left text  color-${dateColor}`}>{getContestStatusText(contest)}</span>
              </div>
              <div className=" text  justify-content-left mb-3 align-items-center d-flex flex-row  ">
                <div className=" d-flex align-items-center flex-row" onClick={() => console.log(contest?.isPrivate)}>
                  <FontAwesomeIcon icon={faPen as IconProp} className={`text  me-3 color-blue`} />

                  <span
                    className={`mx-1 justify-content-left text ${contest.isPrivate || contest.contestType === 'Link to other' ? '' : 'color-blue cursor-pointer'}`}
                    onClick={() => !contest.isPrivate && contest.contestType !== 'Link to other' && goToContestGallery()}
                  >
                    {contest.entries | 0} entries
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-left mb-4 ">
                <div className="d-flex align-items-center flex-row " onClick={() => goToAwards()} style={{ cursor: 'pointer' }}>
                  <FontAwesomeIcon icon={faTrophy as IconProp} className="text color-blue me-3" />
                  <span className="text color-blue">{contest.linkToAward ? `${contest.linkToAward}` : contest.prizes ? `€ ${contest.prizes} award` : 'Awards'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContestCardWLF;
